import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export const fetchExtraServices = createAsyncThunk(
  'extraServices/fetchExtraServices',
  async () => {
    // Get token from cookies
    const token = Cookies.get('token');

    // Configure headers with the token
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    };

    // Make the request with headers
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/v1/master/extra/get-all-extra-services`, config);
    return response.data;
  }
);

const extraServicesSlice = createSlice({
  name: 'extraServices',
  initialState: {
    services: [], // Ensure services is initialized as an array
    loading: false,
    error: null,
    userSelectedServices: []
  },
  reducers: {
    setServices: (state, action) => {

      if (Object.keys(action.payload).length > 0) {
        state.userSelectedServices = [{ ...action.payload }];
      } else {
        state.userSelectedServices = [];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExtraServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExtraServices.fulfilled, (state, action) => {
        state.services = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchExtraServices.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const { setServices } = extraServicesSlice.actions;
export default extraServicesSlice.reducer;
