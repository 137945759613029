import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Link, NavLink } from "react-router-dom"; // Import Link component
import { FaFacebook, FaInstagram, FaArrowRight } from "react-icons/fa";
import MyBooking from "./myBooking";
import { IoIosCall, IoIosMail } from "react-icons/io";
import { MdHeight } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="mt-5 pt-5 ">
      <div className="footer text-white ">
        <MDBFooter
          bgColor="#071011"
          className="text-center text-white text-lg-start text-muted "
        >
          {/* Your footer content */}
          <section className="d-inline">
            <MDBContainer className="text-center text-md-start mt-5">
              {/* Rest of your footer content */}
              <MDBRow className="mt-3 mx-lg-5 mx-md-0 p-1">
                {/* Column for Company name and logo */}
                <MDBCol
                  md="12"
                  lg="4"
                  xl="3"
                  className="mx-auto mb-4 text-white text-start"
                >
                  <h6 className="text-uppercase fw-bold mb-4">
                    
                    <img
                      src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/hatimigold+(2).svg"
                      className="img-fluid" style={{height:"100px", width:"100px"}}
                      alt="image"
                    />
                  </h6>
                  <p className="fs-7">
                    At Hatimi Retreats, we proudly present our properties, each
                    holding a unique and profound significance.
                  </p>
                </MDBCol>
                {/* Column for Products */}
                <MDBCol
                  md="3"
                  lg="2"
                  xl="2"
                  className="mx-auto mb-4 text-white d-flex flex-column gap-2 mt-2 text-start"
                >
                  <p className="footerlist text-start">
                    <Link to="/about" className="footerlist">
                      About
                    </Link>
                  </p>
                  <p>
                    <Link to="/home" className="footerlist">
                      Home
                    </Link>
                  </p>
                  <p>
                    <NavLink to="/destinations" className="footerlist">
                      Destination
                    </NavLink>
                  </p>
                  <p>
                    <Link to="/blogs" className="footerlist">
                      Blogs
                    </Link>
                  </p>
                  {/* <p>
                    <Link to="/react" className="footerlist">
                      Properties
                    </Link>
                  </p> */}
                  <p>
                    <Link to="/contact" className="footerlist">
                      Contact Us
                    </Link>
                  </p>
                </MDBCol>

                {/* Column for Useful links */}
                <MDBCol
                  md="4"
                  lg="3"
                  xl="3"
                  className="mx-auto mb-4 text-white d-flex flex-column gap-2 mt-2 text-start"
                >

                  

                  {/* <p>
                    <Link to="/settings" className="footerlist">
                      Brochure
                    </Link>
                  </p>
                  <p>
                    <Link to="/settings" className="footerlist">
                      Brand Bible
                    </Link>
                  </p> */}
                  <p>
                    <Link to="/privacypolicy" className="footerlist">
                      Privacy Policy
                    </Link>
                  </p>
                  <p>
                    <Link to="/termsandconditions" className="footerlist">
                      Terms and Conditions
                    </Link>
                  </p>
                  <p>
                    <Link to="/cancellationpolicy" className="footerlist">
                    Cancellation policy
                    </Link>
                  </p>
                  <p>
                    <Link to="/reservationpolicy" className="footerlist">
                    Reservation policy
                    </Link>
                  </p>
                  {/* Add similar Links for other useful links */}
                </MDBCol>

                {/* Column for Contact */}
                <MDBCol
                  md="3"
                  lg="3"
                  xl="3"
                  className="mx-auto mb-md-0 mb-4 text-white d-flex flex-column gap-3 mt-2 text-start"
                >
                  <h6 className="text-uppercase fw-bold ">Get in Touch</h6>
                  <p className="d-flex social_icons_footer justify-content-start">
                    <a href="https://www.instagram.com/hatimiretreats/" target="_blank" rel="noopener noreferrer">
                      <FaInstagram className="fs-4" style={{ color: "white" }} />
                    </a>
                  </p>

                  <p className="d-flex align-items-center justify-content-start">
                    <IoIosCall color="light" icon="phone" className="me-3 fs-6 text-white" />{" "}
                    +91 7506305353
                  </p>
                  <p className="d-flex align-items-center justify-content-start">
                    <IoIosMail
                      color="light"
                      icon="envelope"
                      className="me-3 text-white fs-6"
                    />
                    Info@hatimiretreats.com
                  </p>

                  {/* <form>
                    <div className="input-group mb-3  d-flex  align-items-center justify-content-start">
                      <input
                        type="email"
                        className="form-newsletter text-secondary"
                        placeholder="Your Email"
                        aria-label="Your Email"
                        aria-describedby="button-addon2"
                      />
                      <div className="input-group-prepend d-flex align-items-center">
                        <span className="newsletter-btn bg-transparent ">
                          <FaArrowRight className="text-white" />{" "}
                        </span>
                      </div>
                    </div>
                  </form> */}
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            {/* Bottom section of the footer */}
            <div
              className="text-center p-4 text-white d-flex-inline"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
            >
              <p>
                © {new Date().getFullYear()} |{" "}
                <NavLink to="/privacypolicy" className="text-white text-decoration-none">
                  Privacy Policy{" "}
                </NavLink>
                | all rights reserved{" "}
                <a href="#" className="egns-lab">
                  @ Hatimi Property Maintenance Private Limited{" "}
                </a>{" "}
                | Designed By{" "}
                <a
                  href="https://www.entitledarts.com/"
                  className="egns-lab"
                >
                  Entitledarts
                </a>
              </p>

            </div>
          </section>
        </MDBFooter>
      </div>
    </footer>
  );
};

export default Footer;
