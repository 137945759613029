import React, { useEffect } from "react";
import About_Properties from './Properties_Content/About_Properties'
import { useSelector, useDispatch } from "react-redux";
import { HashLoader } from "react-spinners";
import {
    setLocation,
    setLoading,
    fetchProperties,
  } from "../redux/Property/propertySlice";
import { useParams } from "react-router-dom";
import Attractions from "./Properties_Content/Attractions"
const PropertiesDetail = () => {
    const dispatch = useDispatch();
    const {location}=useParams();
    const { properties,loading } = useSelector(
      (state) => state.property
    );
    const filterData = properties?.data?.filter((obj) => obj.property_city === location);
    useEffect(() => {
        // Dispatch the fetchProperties action with the current location
        dispatch(fetchProperties());
      }, [dispatch]);

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        
      };
    
      useEffect(() => {
        // Trigger scroll to top when the component mounts
        scrollToTop();
      }, []);

if (loading) {
    return (
      <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
        <HashLoader color={"#B08E54"} loading={loading} size={50} />
      </div>
    );
  }

  

  return (
   <div className="container">
     {filterData?.map((property, index) => (
        <About_Properties
          key={index} // Provide a unique key for each component
          location={location}
          property_name={property.property_name}
          property_description={property.property_description}
          property_images={property.property_images}
          propertyId={property.property_uid}
        />
      ))}
      {/* <Attractions/> */}
   </div>
  )
}

export default PropertiesDetail