import React, { useState } from "react";
import "../../App.css";
import { AiOutlineCheckCircle } from "react-icons/ai";
import {
  FaUtensils,
  FaSnowflake,
  FaTv,
  FaWifi,
  FaSun,
  FaStar,
  FaArrowRight,
} from "react-icons/fa";
import { BiLibrary } from "react-icons/bi";
import { FaCampground } from "react-icons/fa";
import { FaLocationDot, FaArrowRightLong } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import CancelPolicy from "../Popups/CancelPolicy";

const DaypassBookingcard = () => {
  const amenityIcons = {
    "Swimming pool": FaUtensils,
    "Cricket Turf": FaSnowflake,
    "Football Turf": AiOutlineCheckCircle,
    "Horse Riding": FaTv,
    "Wi-Fi": FaWifi,
    "Flood Lights": FaSun,
    "library": BiLibrary,
    "play-ground": FaCampground
    // Add more amenities and icons as needed
  };

  const products = [
    {
      id: 1,
      name: "Dumas",
      location: "Burhani Baug",
      timing: " 09:00 AM to 10:00 PM",
      amenities: [
        "Swimming pool",
        "Cricket Turf",
        
        "Wi-Fi",
        "Flood Lights",
      ],
      tags: [],
      image: "assets/daypass1.png",
      price: "Rs 6500/N",
    },

    {
      id: 2,
      name: "Matheran",
      location: "Maimoon villa",
      rating: "4.6  ( 29 ratings ) Excellent",
      timing: " 09:00 AM to 10:00 PM",
      amenities: [
        "library",
        "Cricket Turf",
        "play-ground",
        "Wi-Fi",
        "Flood Lights",
      ],
      tags: [],
      image: "/assets/banners/matheran1.jpg",
      price: "Rs 6500/N",
    },
    // Add more products here if needed
  ];

  return (
    <>
      <div>
        <div className="container bootdey">
          {products.map((product) => (
            <div className="col-md-12 " key={product.id}>
              <section className="panel my-5 mx-5 mobileresponsive px-5">
                <div className="panel-body row p-3 rounded">
                  <div className="col-md-4">
                    <div className="mt-3 px-lg-3 px-xs-0 ">
                      <img
                       
                       

                        src={product.image}
                        className="d-block w-100 rounded my-3 "
                        alt=""
                        style={{
                          height: "250px",
                          width: "250px",
                          objectFit: "cover",
                          transition: 'height 0.3s ease, width 0.3s ease'
                        }}
                        onError={(e) => {
                          e.target.src = "/assets/noimage.png";
                          e.target.alt = "No Image";
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-8 p-3">
                    <div className="head daypass-heading d-flex justify-content-between align-items-center mt-3">
                      <h2 className="pro-d-title text-gold m-0">
                        {product.name}
                      </h2>
                      <CancelPolicy />
                    </div>
                    <p className="text-white d-flex align-items-center mb-1 ">
                      <p className="m-0 d-flex align-items-center">
                        {<FaLocationDot className="pe-1" />} {product.location}
                      </p>{" "}
                    </p>
                    <div className="product_meta row">
                      <div className="col-6 text-white ">
                        {" "}
                        <p className="text-gold mb-1">{product.description}</p>
                        
                        <p className="my-1 mt-4 dimension w-75 p-2 rounded-2">
                          Timings : {product.timing}
                        </p>
                        <div className="tagged_as my-2 ">
                          {product.tags.map((tag, index) => (
                            <span key={index} className="fs-7">
                              <a
                                rel="tag"
                                className="text-white tags px-3 fs-7"
                                href="#/"
                              >
                                {tag}
                              </a>
                              {index !== product.tags.length - 1 && " "}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="col-6 booking_amenities text-white ">
                        Offered Amenities
                        <div className="row my-2">
                          <div className="col-6">
                            <ul className="amenities-list">
                              {product.amenities
                                .slice(0, 3)
                                .map((amenity, index) => {
                                  const Icon = amenityIcons[amenity];
                                  return (
                                    <li
                                      key={index}
                                      className="amenity-item gap-2 d-flex align-items-center"
                                    >
                                      {Icon && <Icon className="icon" />}
                                      {amenity}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="col-6">
                            <ul className="amenities-list ">
                              {product.amenities
                                .slice(3)
                                .map((amenity, index) => {
                                  const Icon = amenityIcons[amenity];
                                  return (
                                    <li
                                      key={index}
                                      className="amenity-item gap-2 d-flex align-items-center"
                                    >
                                      {Icon && <Icon className="icon" />}
                                      {amenity}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="topline mt-0"></div>
                  <div className="gap-3 d-flex justify-content-end align-items-center mt-4">
                    <button className="eg-btn btn btn-searchbar ">
                      <NavLink className="nav-link" to="/contact">
                        {/* Book Now */} Contact Us
                      </NavLink>
                    </button>
                  </div>
                </div>
              </section>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DaypassBookingcard;
