
import React, { useEffect, useState } from "react";
import ProductCard from "./Cards/ProductCard";
import { Link, useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Dropdown from "react-bootstrap/Dropdown";
import { FaChevronDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoomData, setSelectedRoomUid, removeSelectedRoomUid } from "../redux/room/roomSlice";
import { increment, setOrderPrice, setOrderTax } from "../redux/room/roomSlice";
import { ToastContainer, Bounce, toast } from "react-toastify";
import SearchBar from "./HomeContent/SearchBar";
import moment from "moment";
import Cart from "./Cart/Cart";
import Cookies from "js-cookie"; 

const Bookings = () => {
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const availableRooms = useSelector((state) => state.room.availableRooms);
  const soldOutRooms = useSelector((state) => state.room.soldOutRooms);
  const propertyData = useSelector((state) => state.room.propertyData);
  const [loading, setLoading] = useState(true);
  const [selectedRoomType, setSelectedRoomType] = useState("");
  const [bookingData, setBookingData] = useState(null);
  const handlePropertiesButtonClick = (obj) => {
    // ... properties-specific button functionality
    setBookingData(obj);
  };
  useEffect(() => {
        const storedData = Cookies.get("searchData");
        if (storedData) {
          const searchData = JSON.parse(storedData);
          setBookingData(searchData);
        
        }
      }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Check if bookingData is available and has the required properties
        if (bookingData && bookingData.dateRange && bookingData.dateRange.startDate && bookingData.dateRange.endDate) {
          await dispatch(fetchRoomData({ propertyId, bookingData }));
        } else {
          console.error("bookingData is missing or invalid.");
        }
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching room data:", error);
        setLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, propertyId, bookingData]);
  
  const handleRoomTypeSelect = (eventKey) => {
    setSelectedRoomType(eventKey);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
  };

  useEffect(() => {
    // Trigger scroll to top when the component mounts
    scrollToTop();
  }, []);

  const filteredRooms = selectedRoomType
    ? availableRooms?.filter((room) => room.room_type === selectedRoomType)
    : availableRooms;


    if (loading) {
      return (
        <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
          <HashLoader color={"#B08E54"} loading={loading} size={50} />
        </div>
      );
    }
    
  
  return (
    <>
      
        <div className=" pt-2">
          <div className="my-3 mobilepaddingmargin">
            <SearchBar check="rooms" className="bookings"  onButtonClick={handlePropertiesButtonClick} buttonLabel="Update Search" dropdown="room"/>
          </div>
          <div className="container px-5 mobileresponsive room_type d-flex justify-content-end">
            <Dropdown onSelect={handleRoomTypeSelect} className="border rounded d-flex align-items-center">
              <Dropdown.Toggle
                variant="outline-darkgreen text-white"
                id="dropdown-basic"
                className="d-flex gap-2 align-items-center"
              >
                {selectedRoomType ? selectedRoomType : "Room Type"}
                <FaChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu className="darkgreen text-white dropdown_border">
                <Dropdown.Item eventKey="" className="drop-down-li text-white px-5 mx-0 bg-transparent border-0">All Rooms</Dropdown.Item>
                {Array.from(new Set(availableRooms?.map((room) => room.room_type)))?.map(
                  (type) => (
                    <Dropdown.Item key={type} eventKey={type} className="drop-down-li text-white px-5 mx-0 bg-transparent border-0">
                      {type}
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>

            {/* Cart */}
            <Cart />
          </div>
      

          <div className="container bootdey">
            {filteredRooms.length === 0 ? (
              <h2 className=" text-white my-5 py-5 text-center mobileresponsive mx-5 px-5">
                No rooms available for this property
              </h2>
            ) : (
              filteredRooms?.map((room) => (
                <ProductCard
                  filtered={availableRooms}
                  key={room._id}
                  roomImages={room.room_images}
                  image={room.room_images[0]}
                  image0={room.room_images[1]}
                  image1={room.room_images[2]}
                  image2={room.room_images[3]}
                  room_charge={room.room_charge}
                  room_name={room.room_name}
                  room_type={room.room_type}
                  room_size={room.room_size}
                  bed_size={room.bed_size}
                  availableRooms={room.availableRooms}
                  location={room?.property_city}
                  property_city={propertyData?.property_city}
                  property_name={propertyData?.property_name}
                  base_guest_occupancy={room.base_guest_occupancy}
                  max_guest_occupancy={room.max_guest_occupancy}
                  extra_bed_count={room.extra_bed_count}
                  rating="(Rating Placeholder)"
                  dimension={`Room Size: ${room.room_size}`}
                  tags={[]}
                  amenities={room.room_amenities}
                  showButton={true}
                  is_booked={false}
                  // addToCart={ handleRoomSelection}
                  propertyId={propertyId}
                  roomId={room.room_uid}
                  property_image_url={propertyData.property_image_url}
                />
              ))
            )}
          </div>

          <div className="container bootdey">
            {soldOutRooms?.map((room) => (
              <ProductCard
              filtered={soldOutRooms}
                key={room._id}
                roomImages={room.room_images}
                image={room.room_images[0]}
                image0={room.room_images[1]}
                image1={room.room_images[2]}
                image2={room.room_images[3]}
                room_charge={`${room.room_charge}`}
                room_tax={
                  Number(room.room_charge) >= 7500
                    ? Number(room.room_charge * (18 / 100))
                    : Number(room.room_charge) * (12 / 100)
                }
                room_name={room.room_name}
                room_type={room.room_type}
                room_size={room.room_size}
                bed_size={room.bed_size}
                
                location={room.property_city}
                property_city={propertyData?.property_city}
                property_name={propertyData?.property_name}
                max_guest_occupancy={room.max_guest_occupancy}
                base_guest_occupancy={room.base_guest_occupancy}
                rating="(Rating Placeholder)"
                dimension={`Room Size: ${room.room_size}`}
                tags={[]}
                amenities={room.room_amenities}
                showButton={true}
                is_booked={true}
                propertyId={propertyId}
                roomId={room.room_uid}
                property_image_url={propertyData?.property_image_url}
              />
            ))}
          </div>
        </div>
      
    </>
  );
};

export default Bookings;
