import React, { useState } from "react";
import "../../App.css";

import { FaLocationDot } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const Cancel = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const products = [
    {
      id: 1,
      name: "Misk Presidential Suite",
      location: "Maimoon villa ",
      amount: " 3780",
      guest: "1",
      room: "2",
      bookingid: "8520",
      image: "assets/bookingimg.png",
    },

    // Add more products here if needed
  ];

  return (
    <>
      <div className="px-5 pt-5 text-white  mobileresponsive container">
        <h2 className="px-5">Booking Details</h2>
      </div>
      <div>
        <div className="container bootdey">
          {products.map((product) => (
            <div className="col-md-12 " key={product.id}>
              <section className="panel my-5 mx-5 mobileresponsive px-5">
                <div className="panel-body row p-3 rounded">
                  <div className="col-md-4">
                    <div className="pro-img-details px-3 my-3 ">
                      <img
                        src={product.image}
                        className="w-100 rounded"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-8 p-3 text-white">
                    <div className="head d-flex justify-content-between  align-items-center mt-3">
                      <h2 className="pro-d-title text-gold m-0">
                        {product.name}
                      </h2>
                      <div className="d-flex flex-column cursor-pointer ">
                        <p className="text-white">
                          Booking ID : {product.bookingid}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-white d-flex align-items-center mb-1 ">
                        <p className="m-0"> {product.location}</p>{" "}
                        <p className="px-2 d-flex align-items-center m-0">
                          {<FaLocationDot className="pe-1" />} Matheran
                        </p>
                      </div>
                      <div className="d-flex justify-content-end">
                        <p className="text-center">Need Help?</p>
                      </div>
                    </div>

                    <div className="product_meta row">
                      <div className="col-12 text-white pt-5">
                        {" "}
                        <p className=" mb-1">Amount paid : {product.amount}</p>
                        <div className="d-flex gap-2">
                          <p className=" mb-1"> {product.guest} Guests</p>
                          <p className=" mb-1"> {product.room} Rooms</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className=" mb-1">Nov 20,2022 to Nov 22,2022</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
  <div className="row cancel-policy text-white mx-5 px-5 mobileresponsive gap-5">
    <div className="bg-chocolate p-5 rounded col-sm-6 mb-3">
      <h5> Cancellation Policy</h5>

      <div className="modal-body fs-7">
        Review our cancellation policy before making a reservation, as
        cancellation fees may apply. <br />
        <p className="fs-8 py-3">
          A. Cancel 48 hours before check-in: No charge. <br />
          B. Cancel between 48 and 24 hours before check-in: 20% of the total amount.
          <br />
          C. Cancel within 24 hours of check-in: 30% of the total amount. <br />
          D. No-shows: Full booking amount may be charged. <br />
          E. Advance Purchase/Non-Refundable Bookings: Cancellations, changes, or
          no-shows result in non-refundable bookings. <br />
          F. Refunds may take 7-10 business days. <br />
        </p>
        Email support@hatimiproperties.com for cancellations failure means loss of
        payments.
      </div>
     
    </div>
    <div className="price-breakup col-sm-5 p-3 mb-3 bg-chocolate rounded">
     <div className="price pt-3 ">
      <p className="fs-4 px-3 pb-3">Price breakup</p>
     </div>
      <div className="text-white p-3 fs-5">
        <div className="d-flex justify-content-between mb-3 ">
          <p>Room price</p>
          <p>₹100</p>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <p>Taxes & fees</p>
          <p>100</p>
        </div>
        
        <div className="d-flex justify-content-between mb-3">
          <p>Coupon discount</p>
          <p>₹100</p>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <p>Total Amount:</p>
          <p>₹100</p>
        </div>

        <div className="gap-3 d-flex justify-content-end pt-5">
        <button className="btn btn-round btn-light text-white px-4" type="button">
          <NavLink className="nav-link" to="">
            Cancel
          </NavLink>
        </button>
        <button className=" btn btn-danger px-4 rounded">
          <NavLink className="nav-link" to="/proceedcancel">
            Proceed
          </NavLink>
        </button>
      </div>
      </div>
    </div>
  </div>
</div>

     
    </>
  );
};

export default Cancel;
