import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import "../App.css";
import ImageChanger from "../components/HomeContent/ImageChanger";
import Gateway from "../components/HomeContent/Gateway";
import Tourplaces from "../components/HomeContent/Tourplaces";
import Awesome from "../components/HomeContent/Awesome";
import Amenities from "../components/HomeContent/Amenities";
import Blogs from "../components/HomeContent/Blogs";
import SearchBar from "../components/HomeContent/SearchBar";
import Instagramfeed from "../components/HomeContent/Instagramfeed";
import Testimonials from "../components/HomeContent/Testimonials";
import Preloader from "../components/Preloader";
import { HashLoader } from "react-spinners";

const Home = () => {
  const navigateTo = useNavigate();
  const handleHomeButtonClick = (location) => {
    navigateTo('/home/properties');
  };

  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 3000); // 20 seconds

  //   return () => clearTimeout(timer);
  // }, []);

  // if (loading) {
  //   return (
  //     <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
  //       {/* HashLoader component from react-spinners */}
  //       <HashLoader color={"#B08E54"} loading={loading} size={50} />
  //     </div>
  //   );
  // }
  return (
    <>
      <div className="">
        <div className="imagechanger">
          <ImageChanger />
        </div>

        <div className="homesearchbar">
          <SearchBar className="" check="home" onButtonClick={handleHomeButtonClick} buttonLabel="Search" dropdown="home"/>
        </div>

        <div className="gateway">
          <Gateway />
        </div>

        <div>
          <Tourplaces />
        </div>

        <div>
          <Awesome/>
        </div>

        <div className="">
          <Amenities/>
        </div>

        <div>
          <Blogs/>
        </div>
        <div>
          <Instagramfeed/>
        </div>

        {/* <div>
          <Testimonials/>
        </div> */}
      </div>
    </>
  );
};

export default Home;
