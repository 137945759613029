// src/pages/Blogs.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogs } from '../redux/Blogs/blogSlice';
import BlogCard from '../components/Cards/BlogCard';
import { HashLoader } from 'react-spinners';
import Instagramfeed from '../components/HomeContent/Instagramfeed';

const Blogs = () => {
  const dispatch = useDispatch();
  const { blogs, loading, error } = useSelector((state) => state.blogs);

  useEffect(() => {
    dispatch(fetchBlogs());
  }, [dispatch]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
        <HashLoader color={"#B08E54"} loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return <p className="text-center text-danger">Error loading blogs: {error}</p>;
  }

  return (
    <>
      <div className='container text-white my-5'>
        <div className=''>
          <h1 className='text-center '>News & Articles</h1>
          <p className='fs-6 text-center'>#1 Sneak-Peak of Authentic Hospitality</p>
        </div>
        <div className='blogs px-5 mx-5 gap-5 mobileresponsive'>
          <div className="row ps-0 mt-5 d-flex justify-content-center gap-3">
            {blogs.map((blog) => (
              <div className="blog-card text-white blog-background rounded px-0" key={blog._id}>
                <BlogCard
                  key={blog._id}
                  imageUrl={blog.imageUrl}
                  title={blog.title}
                  description={blog.description}
                  button="View more"
                  showButton={true}
                  imageHeight={null}
                  blogId={blog._id}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Instagramfeed />
    </>
  );
};

export default Blogs;
