// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {roomReducer} from './room/roomSlice';
import counterslice from './room/roomSlice'
import {orderDetail} from './room/roomSlice'
import { counterReducer } from "./room/roomSlice";
import { orderDetailReducer } from "./room/roomSlice";
import authReducer from './Auth/AuthSlice'
import propertyReducer from './Property/propertySlice';
import {thunk} from 'redux-thunk'; // Import the thunk middleware
import extraServicesReducer from './ExtraServices/ExtraServices';
import blogReducer from './Blogs/blogSlice';
import blogDetailReducer from './Blogs/blogDetailSlice';
import roomDetailReducer from './room/roomDetailSlice';
import myBookingsReducer from './bookings/myBookingsSlice'
const store = configureStore({
  reducer: {
    room: roomReducer,
    counter: counterReducer,
    orderDetail: orderDetailReducer,
    property: propertyReducer,
    auth:authReducer,
    ExtraServices: extraServicesReducer,
    blogs: blogReducer,
    blogDetail: blogDetailReducer,
    myBookings: myBookingsReducer,
    roomDetails: roomDetailReducer,
  },
});

export default store;
