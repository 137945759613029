import React, { useEffect } from 'react';

const CancelPolicy = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    
  };

  useEffect(() => {
    // Trigger scroll to top when the component mounts
    scrollToTop();
  }, []);
  return (
    <>
      <div className="container">
        <div className="text-white mx-5 px-5 py-3 mobileresponsive">
          <section className="mb-5 mt-3">
            <h1 className="fs-1">Cancellation Policy</h1>
          </section>
          <div className="text-white">
                    {/* Full Refund Eligibility */}
                    <section className="">
                      <h2 className="fs-4 fw-bold">Full Refund Eligibility:</h2>
                      <ul className="fs-6">
                        <li>
                        Cancellations made at least one week before the check-in date qualify for a
                        full refund.
                        </li>
                      </ul>
                    </section>

                    {/* Late Cancellation Fees */}
                    <section className="">
                      <h2 className="fs-4 fw-bold">Late Cancellation Fees:</h2>
                      <ul className="fs-6">
                        <li>
                          <strong>
                          Cancellations between 1 week to 48 hours before check-in (12:00 p.m.):
                          </strong>{" "}
                          10% deduction from the total booking amount.
                        </li>
                        <li>
                          <strong>
                          Cancellations between 48 hours to 24 hours before check-in (12:00
                            p.m.):
                          </strong>{" "}
                          30% deduction from the total booking amount.
                        </li>
                        <li>
                          <strong>
                          Cancellations between 24 hours to 12 hours before check-in (12:00
                            p.m.):
                          </strong>{" "}
                          50% deduction from the total booking amount.
                        </li>
                        <li>
                          <strong>
                          Cancellations within 12 hours before check-in (12:00 p.m.):
                          </strong>{" "}
                          No refund.
                        </li>
                        <li>
                          <strong>Promotional and Discounted Booking:</strong>{" "}
                          No refund.
                        </li>
                        <li>
                        <strong>The management does not initiate partial refunds.</strong>
                        </li>
                        <li>
                        <strong>Convenience fees will not be refunded.</strong>
                        </li>
                      </ul>
                     
                    </section>

                    {/* No-Show Policy */}
                    <section className="">
                      <h2 className="fs-4 fw-bold">No-Show Policy:</h2>
                      <ul className="fs-6">
                        <li>
                        If you do not show up for your reservation, the entire booking amount will be
                        forfeited with no option to reschedule.
                        </li>
                      </ul>
                    </section>

                    {/* Cancellation Procedure */}
                    <section className="mb-4">
                      <h2 className="fs-4 fw-bold">Cancellation Procedure:</h2>
                      <p className="fs-6">
                        To cancel your reservation, please email
                        reservations@hatimiretreats.com from your registered ITS
                        email, including a copy of the invoice and a cancelled
                        cheque. Refunds may take 7-10 working days to process.
                      </p>
                    </section>

                    {/* Management-Initiated Cancellations */}
                    <section className="mb-4">
                      <h2 className="fs-4 fw-bold">
                        Management-Initiated Cancellations:
                      </h2>
                      <p className="fs-6">
                        The management reserves the right to cancel the guest
                        reservations.
                      </p>
                    </section>
                  </div>
        </div>
      </div>
    </>
  );
};

export default CancelPolicy;
