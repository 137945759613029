import React from 'react';
import { useSelector } from 'react-redux';

const MyProfile = () => {
    const { customerDetails, customerStatus, customerError } = useSelector((state) => state.auth);

    // Extracting first letters of first name and last name
    const firstNameInitial = customerDetails?.fullName?.split(' ')[0]?.[0] || '';
    const lastNameInitial = customerDetails?.fullName?.split(' ')[1]?.[0] || '';

    return (
        <div className='container text-white mt-5'>
            <div className='mx-5 px-5 mobileresponsive'>
                <h1>My Profile</h1>
                <div className='row my-5'>
                    <div className='col-md-4 text-center'>
                        <div className='profile-image mb-3'>
                            {/* Displaying initials instead of an image */}
                            <div className='initials-circle rounded-circle'>
                                {firstNameInitial}{lastNameInitial}
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <form>
                            <div className='form-group border-gray'>
                                <label htmlFor='name'>Name</label>
                                <input type='text' className='form-control contact_form bg-dark' id='name' style={{ padding: "10px" }} defaultValue={customerDetails?.fullName} disabled />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='email'>Email</label>
                                <input type='email' className='form-control contact_form bg-dark' id='email' style={{ padding: "10px" }} defaultValue={customerDetails?.email} disabled/>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='mobile'>Mobile</label>
                                <input type='text' className='form-control contact_form bg-dark' id='mobile' style={{ padding: "10px" }} defaultValue={customerDetails?.mobile_no} disabled/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile;
