import React, { useEffect } from 'react';
import { IoLogInOutline } from "react-icons/io5";
import { useLocation } from 'react-router-dom';


const Login = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
          document.body.classList.add('home-login');
        } else {
          document.body.classList.remove('home-login');
        }
      }, [location.pathname]);

    const handleLogin = () => {
        window.location.href = 'https://www.its52.com/Login.aspx?OneLogin=HTMIRETRS';
      };

  return (
    <div className="container-fluid pe-3 login-container">
      <div className="row">
        <div className="col-lg-6 login-image-container">
        <div className="gradient-overlay"></div>
          <img src="/assets/logoimg2.jpg" className="img-fluid rounded-2 login-image" alt="Left Image" />
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center px-3 text-container">
          <img src="/assets/hatimigold.svg" className="login-logo" alt="Logo" />
          <h2 className="text-center text-white py-4 w-75 mobile-text">In order to book a stay with Hatimi Retreats, Please login with your ITS-ID.</h2>
          <button className="eg-btn btn px-4" onClick={handleLogin}>
            Login with ITS <IoLogInOutline className='fs-3 ms-3'/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
