import React, { useEffect } from 'react';


const About = () => {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        
      };
    
      useEffect(() => {
        // Trigger scroll to top when the component mounts
        scrollToTop();
      }, []);
  return (
    <div className="container">
      <div className="row py-5 mx-5 px-5 mobileresponsive">
        <div className="col-md-7 text-white ">
          <h1>About Us</h1>
          <p className='pt-4 fs-7'>
            Hatimi Retreats treats you with the best guest service at its collection of homestays, villas, and resorts, with its humble staff. Experience culinary dining with high-quality food, great rooms, and picturesque views framed through our interesting architecture. Guests' safety and comfort are our utmost priority.
          </p>
          <h2 className='pt-5'>Our Vision</h2>
          <p className='fs-7'>
            By the Quranic teachings, our vision is to provide mumineen their retreat sanctuaries distinguished by an “Itmami Maufu” where they can rejoice and relish quality time.
          </p>
          <h2 className='pt-4'>Our Purpose</h2>
          <p className='fs-7'>
            We draw inspiration from the lofty instructions of Rasulullah SAW and his Dai Al-Dai al-Ajal al-Fatemi Syedna Aali Qadar Mufaddal Saifuddin TUS who directs us to reinvigorate our spirit and intellect from time to time, lower our stresses and lead a healthy life.
          </p>
          <h2 className='pt-5'>Our Offices</h2>
          <h3 className='pt-3'>Registered Address</h3>
          <p className='fs-7'>
           Floor-4, Plot No-896, Fort House, Dadabhai Nawroji Road, Handloom House, Fort Mumbai, Mumbai City MH 400001 IN
          </p>
          <h3 className='pt-3'>Operational Address</h3>
          <p className='fs-7'>
          1st Floor, Fakhri Makaan, Bora Bazaar St, Opp Jain Medical, Fort, Mumbai 400001.
          </p>
        </div>
        <div className="col-md-5 text-white pt-3">
        <img src="/assets/logoimg2.jpg" className="img-fluid rounded-2" style={{height:"80vh",marginTop:""}} alt="Left Image" />
        </div>
      </div>
    </div>
  );
}

export default About;
