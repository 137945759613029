import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FaLocationDot } from 'react-icons/fa6';
import { HashLoader } from 'react-spinners';
import { fetchMyBookings } from '../redux/bookings/myBookingsSlice';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const MyBooking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myBookings, loading, error } = useSelector((state) => state.myBookings);
  const { customerDetails } = useSelector((state) => state.auth);

  useEffect(() => {
    if (customerDetails?._id) {
      dispatch(fetchMyBookings(customerDetails._id));
    }
  }, [dispatch, customerDetails]);

  const formatDate = (dateString) => {
    return moment(dateString).format('MMM D, YYYY');
  };

  const getClassByTask = (task) => {
    if (task === 'Finished') {
      return 'text-green';
    } else if (task === 'Upcoming') {
      return 'text-warning';
    } else if (task === 'Cancelled') {
      return 'text-danger';
    } else {
      return '';
    }
  };

  const handleNeedHelp = () => {
    navigate('/contact');
  };

  if (loading) {
    return (
      <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
        <HashLoader color={'#B08E54'} loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <div className="mx-5 px-5 mobileresponsive text-white text-center d-flex justify-content-center align-items-center" style={{ height: '38vh' }}>
          <h2>Error fetching booking data</h2>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="px-5 pt-3 mt-5 text-white col-sm-m-0 container mobileresponsive">
        <h2 className="px-5 col-sm-p-0 mobileresponsive">Booking history</h2>
      </div>
      <div>
        <div className="container bootdey">
          {myBookings?.length > 0 ? (
            myBookings?.map((booking) => (
              <div className="col-md-12" key={booking._id}>
                <section className="panel my-5 mx-5 mobileresponsive px-5">
                  <div className="panel-body row p-3 rounded">
                    <div className="col-md-4">
                      <div className="pro-img-details px-3">
                        <p className={`m-0 dark p-1 d-flex justify-content-center bookingprice ${getClassByTask(booking.booking_status)}`}>
                          {booking.booking_status === 'success' ? (
                            <span className="text-green">Success</span>
                          ) : booking.booking_status === 'cancel' ? (
                            <span className="text-danger">Cancelled</span>
                          ) : (
                            <span className="text-warning">Upcoming</span>
                          )}
                        </p>
                        <img src={booking.room_info[0].roomImages[0]} className="w-100 rounded" alt="" />
                      </div>
                    </div>
                    <div className="col-md-8 p-3 text-white">
                      <div className="head d-flex flex-wrap justify-content-between align-items-center mt-3">
                        {booking.room_info?.length > 0 && (
                          <h2 className="pro-d-title text-gold m-0">{booking.room_info[0].room_type}</h2>
                        )}
                        <div className="d-flex flex-column cursor-pointer">
                          <p className="text-white">Booking ID: {booking.booking_id}</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="text-white d-flex align-items-center mb-1">
                          <p className="m-0"> {booking.location}</p>
                          <p className="px-2 d-flex align-items-center m-0">
                            {<FaLocationDot className="pe-1" />} {booking.property_name}
                          </p>
                        </div>
                        <div className="d-flex justify-content-end">
                          <p className="text-center cursor-pointer" onClick={handleNeedHelp}>Need Help?</p>
                        </div>
                      </div>
                      <div className="product_meta row">
                        <div className="col-12 text-white pt-5">
                          <div className="d-flex">
                            <p className="d-flex gap-1 align-items-center">Amount Paid: ₹ <span className="fs-5">{booking.paid_amount}</span></p>
                          </div>
                          <div className="d-flex gap-2">
                            <p className="mb-1">{booking.adults} Guests</p>
                            <p className="mb-1">{booking.number_of_rooms} Rooms</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-1">
                              {formatDate(booking.check_in)} to {formatDate(booking.check_out)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ))
          ) : (
            <div className="col-md-12">
              <section className="panel my-5 mx-5 mobileresponsive px-5">
                <div className="panel-body row p-3 rounded">
                  <div className="col-md-12 text-center">
                    <h2 className="text-white">No bookings found</h2>
                    <p className="text-white">It seems you haven't made any bookings yet.</p>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyBooking;
