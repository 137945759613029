import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { SlCalender } from "react-icons/sl";
import { MdOutlineCancel } from "react-icons/md";
import { NavLink } from "react-router-dom";

const ProceedCancel = () => {
    const [showModal, setShowModal] = useState(false);

    const handleModalClose = () => {
      setShowModal(false);
    };
  
    const handleProceed = () => {
      // Add your logic here for handling the Proceed action
      // For example, you can redirect the user or perform any other necessary actions
      setShowModal(false);
    };
  return (
    <div className="container mt-5 px-5 pt-5 mobileresponsive">
      <div className="row proceedCancel bg-chocolate rounded text-white p-5 mx-5 mobileresponsive ">
        <div className="col-6">
          <div className="d-flex gap-4 align-items-center">
            <MdOutlineCancel className="fs-1 " />
            <div className="d-flex flex-column ">
              <p className="fs-4">Cancel you stay</p>
              <p>Maimoon villa</p>
            </div>
          </div>
          <div className="d-flex gap-4 align-items-center pt-3">
            <SlCalender className="fs-1 " />
            <div className="d-flex flex-column ">
              <p className="fs-4">Nov 20,2022 - Nov 22,2022</p>
              <p>2 Guests, 1 Room</p>
            </div>
          </div>
          <div class="cancelparagraph pt-5">
    <p class="fs-4">Select reason for cancelling this booking</p>
    <p>This will help us in providing a better experience</p>

    <div class="radio-container p-3">
        <div className="d-flex gap-5">
        <div class="form-check ">
            <input class="form-check-input" type="radio" name="cancellationReason" id="reason1"/>
            <label class="form-check-label" for="reason1">I want to change dates</label>
        </div>

        <div class="form-check">
            <input class="form-check-input" type="radio" name="cancellationReason" id="reason2"/>
            <label class="form-check-label" for="reason2">I want to add/delete guest</label>
        </div>
        </div>
        
<div className="d-flex gap-5">
<div class="form-check">
            <input class="form-check-input" type="radio" name="cancellationReason" id="reason3"/>
            <label class="form-check-label" for="reason3">My Plan is cancelled</label>
        </div>

        <div class="form-check ps-5">
            <input class="form-check-input" type="radio" name="cancellationReason" id="reason4"/>
            <label class="form-check-label" for="reason4">Other</label>
        </div>
</div>
        
    </div>
</div>
        </div>
        <div className="col-6">
    <div className="image ">
        <img src="assets/bookingimg.png" className="w-50 rounded" alt="" />
        <div className="info">
        <h2 className="text-gold pt-3">Standard Room</h2>
        <p>Maimoon villa</p>
        <p>Matheran</p>
        <p>Contact 9979988990</p>

        <div className="text-white pt-5 fs-5">
        <div className="d-flex justify-content-between mb-3 ">
          <p>Total  Amount Paid</p>
          <p>₹ 1753</p>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <p>Cancelation fee 20%</p>
          <p>₹ 1753</p>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <p>Refundable amount</p>
          <p>₹ 1402.4</p>
        </div>

        <div className="gap-3 d-flex justify-content-end pt-5">
            <button
              className="btn btn-round btn-light text-white px-4"
              type="button"
            >
              <NavLink className="nav-link" to="">
                Cancel
              </NavLink>
            </button>
            <button
              className="btn btn-danger px-4 rounded"
              onClick={() => setShowModal(true)}
            >
              <NavLink className="nav-link" to="">
                Proceed
              </NavLink>
            </button>
          </div>
        </div>
      </div>

      {/* React Bootstrap Modal */}
      <Modal show={showModal} onHide={handleModalClose} className=" d-flex align-items-center">
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
          {/* Add your modal content here */}
          <p className="px-5">This is the final step of cancellation.
             Once cancelled your booking can not be reinstated</p>
        </Modal.Body>
        <div className="topline mt-2"></div>
        <Modal.Footer className="border-0">
        <div className="gap-3 d-flex justify-content-center">
            <button
              className="btn btn-round btn-light text-white px-4"
              type="button"
            >
              <NavLink className="nav-link" to="">
                Cancel
              </NavLink>
            </button>
            <button
              className="btn btn-danger px-4 rounded"
              onClick={() => setShowModal(true)}
            >
              <NavLink className="nav-link" to="/refundscreen">
                Proceed
              </NavLink>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
      </div>
    </div>
</div>

       
  );
};

export default ProceedCancel;
