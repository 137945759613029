import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogDetailData } from '../../redux/Blogs/blogDetailSlice'; // Adjust the path as needed
import { HashLoader } from 'react-spinners';

const BlogDetail = () => {
  const { blogId } = useParams();
  const dispatch = useDispatch();
  const { blogDetailData, loading, error } = useSelector((state) => state.blogDetail);

  useEffect(() => {
    dispatch(fetchBlogDetailData(blogId));
  }, [blogId, dispatch]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner w-100 d-flex justify-content-center align-items-center">
        <HashLoader color={"#B08E54"} loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!blogDetailData) {
    return <div>No blog data available</div>;
  }

  return (
    <div className="my-5 py-5">
      <div className="container">
        <div className="row justify-content-center text-white">
          <h1 className="text-gold text-center my-4 fs-1 pb-5 mobileresponsive">{blogDetailData.title}</h1>
          <div className="blog-detail-page mobileresponsive">
            {blogDetailData.details.descriptions.map((description, index) => (
              <div
                className="row justify-content-center align-items-center"
                key={index}
              >
                <>
                  <div className="col-md-6">
                    <p>{description.description1}</p>
                  </div>
                  <div className="col-md-6">
                    <img
                      src={blogDetailData.details.images[0]}
                      alt={`Image ${index / 2}`}
                      style={{ width: "400px", height: "350px", objectFit: "cover", borderRadius: "10px" }}
                      className="blog-detail-image mobileresponsive"
                    />
                  </div>

                  <div className="col-md-6 mt-5 pt-5">
                    <img
                      src={blogDetailData.details.images[1]}
                      alt={`Image ${(index - 1) / 2}`}
                      style={{ width: "400px", height: "350px", objectFit: "cover", borderRadius: "10px" }}
                      className="blog-detail-image m-0 mobileresponsive"
                    />
                  </div>
                  <div className="col-md-6 pt-5 mt-5">
                    <p>{description.description2}</p>
                  </div>

                  <div className="col-md-6 pt-5">
                    <p>{description.description3}</p>
                  </div>
                  <div className="col-md-6 mt-5 pt-5">
                    <img
                      src={blogDetailData.details.images[2]}
                      alt={`Image ${index / 2}`}
                      style={{ width: "400px", height: "350px", objectFit: "cover", borderRadius: "10px" }}
                      className="blog-detail-image mobileresponsive"
                    />
                  </div>
                </>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
