import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { HashLoader } from "react-spinners";
import "bootstrap/js/dist/dropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { IoCart, IoClose } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRoomUid,
  removeSelectedRoomUid,
  setExtraBedPrice,
} from "../../redux/room/roomSlice";
import {
  increment,
  decrement,
  setOrderPrice,
  setOrderTax,
  setHighestSelectedRoomPrice
} from "../../redux/room/roomSlice";

import BookNow from "../Popups/BookNow";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { IoMdClose } from "react-icons/io";
import CartInfo from "./CartInfo";
import Cookies from 'js-cookie';
import { SlCalender } from "react-icons/sl";

const Cart = () => {
    const [availableRooms, setAvailableRooms] = useState([]);
    const [properties, setProperties] = useState({});
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [bookingData, setBookingData] = useState(null);
    const { services, loading, error,userSelectedServices } = useSelector((state) => state.ExtraServices);
    const {selectedRoomUid,cartLength} = useSelector((state) => state.room);
    const dispatch = useDispatch();
    const handlePropertiesButtonClick = (obj) => {
      // ... properties-specific button functionality
      setBookingData(obj)
    };

    useEffect(() => {
      const storedData = Cookies.get("searchData");
      if (storedData) {
        const searchData = JSON.parse(storedData);
        setBookingData(searchData);
   
      }
    }, []);
  
    const handleButtonClick = () => {
        setShowOffcanvas(!showOffcanvas);
      };
    const handleGuest = () => {
        if (bookingData && bookingData.adults) {
          return bookingData.adults;
        } else {
          return 0; // Default value if the data is not available or not in the expected format
        }
      };
    const {totalRoomCharge,totalCapacity,highestPrice,extraServicesPrice}=selectedRoomUid.reduce((acc,room)=>{
        const roomC=room.max_base_guest_occupancy ? room.max_base_guest_occupancy + room.initialBedCount : room.base_guest_occupancy+room.initialBedCount;
        const roomP=room.room_charge ? room.room_charge : room.base_room_charge;
        if(room.base_room_charge > acc.highestPrice){
          acc.highestPrice=room.base_room_charge;
        }
        acc.extraServicesPrice=acc.extraServicesPrice + (room.extra_services.length > 0 && room.extra_services[0]?.service_count *  room.extra_services[0]?.service_price)
        acc.totalRoomCharge=acc.totalRoomCharge+Number(roomP);
        acc.totalCapacity=acc.totalCapacity+Number(roomC);
        return acc;

      },{totalRoomCharge:0,totalCapacity:0,highestPrice:0,extraServicesPrice:0});
      
      const handleButton = () => {
        setShowModal(!showModal);
      };
      const handleTotalCharge = () => {
        dispatch(setOrderPrice({ price: totalRoomCharge }));
        dispatch(setHighestSelectedRoomPrice({highestPrice:highestPrice}));
        dispatch(setExtraBedPrice({bedPrice:extraServicesPrice}));
        handleButton();
      };

      const handleCloseModal = () => {
        setShowModal(false);
        // Add any additional logic you need when the modal is closed
      };

      //console.log("services",services);

      
      const groupedRooms = selectedRoomUid.map((room,ind) => {
        return {
          room_type:room.room_type,
          room_count:room.initialCount,
          base_guest_occupancy : room.max_base_guest_occupancy ? room.max_base_guest_occupancy : room.base_guest_occupancy,
          extra_beds:room.initialBedCount
        }
      });

      console.log("selectedRoomUid",selectedRoomUid);
      
  
  return (
    <>
       <Link type="button" onClick={handleButtonClick} className="px-5 mobileresponsive">
            <IoCart className="fs-1 text-gold " />
            <span className="cart-count    text-white">{cartLength}</span>
          </Link>

          <div
            className={`offcanvas  offcanvas-end ${
              showOffcanvas ? "show" : ""
            } `}
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header ">
              <h2 className=" text-gold ps-4 m-0">Cart</h2>
              <IoClose
                className="text-white fs-3" // Added "text-white" class here
                onClick={handleButtonClick}
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body px-5 roomscrollbar">
              <CartInfo />
            </div>
            <div className="footers d-flex justify-content-end flex-column align-items-end p-4 ">
              <h3>Base Price : ₹{totalRoomCharge}</h3>
              <p>{extraServicesPrice>0 ?(`Extra Services : ₹ ${extraServicesPrice}`):(null)}</p>
              <p> {cartLength} rooms per night</p>
            </div>
            <div className="book align-self-end pe-4 pb-4">
              <button type="button" className="btn btn-searchbar" disabled={totalCapacity >= handleGuest() ? false : true}>
                <Link
                  to=""
                  className="nav-link"
                  onClick={() => handleTotalCharge()}
                  
                >
                  Book Now
                </Link>
              </button>

              {/* Modal */}
              <Modal
                show={showModal}
                onHide={handleCloseModal}
                className="d-flex align-items-center"
              >
                <Modal.Header className="px-4 mt-3">
                  <h3 className="m-0">Review booking Selection</h3>
                  <IoMdClose
                    className="fs-4 cursor-pointer"
                    onClick={handleCloseModal}
                  />
                </Modal.Header>
                <Modal.Body className="px-4 ">
                  <p className="mb-2 fs-6">
                    Search request for {bookingData ? handleGuest() : 0}{" "}
                    Guests. Accommodated  {totalCapacity}{" "}
                    Guests.
                  </p>
                  <div className="d-flex gap-3 align-items-center px-2 pb-3">
            <SlCalender className="fs-6 text-gold" />
            <div className="d-flex flex-column ">
              <p className="text-gold">{bookingData?.dateRange?.startDate} - {bookingData?.dateRange?.endDate}</p>
             
            </div>
                  </div>
                  <ul>
                    
                    {groupedRooms.map((groupedRoom, index) => (
                      <li key={index}>
                        {groupedRoom.room_count} * {groupedRoom.room_type} -{" "}
                        {groupedRoom.base_guest_occupancy} guests -{" "} {groupedRoom.extra_beds} extra beds
                      </li>
                    ))}
                  </ul>
                </Modal.Body>
                <Modal.Footer className="">
                  {/* <Link className="nav-link btn-gold text-black p-2" onClick={handleCloseModal}>
                    Add Room
                  </Link> */}
                 
                    <Link
                      to="/home/orderdetail"
                      className="nav-link btn-gold text-black p-2"
                      onClick={() => setShowModal(false)}
                    >
                      Confirm Booking
                    </Link>
                  
                </Modal.Footer>
              </Modal>
            </div>
          </div>
    </>
  )
}

export default Cart