import React, { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import moment from "moment";
import Cookies from 'js-cookie';

const Bookinginfo = (props) => {
  
  const [bookingData, setBookingData] = useState(null);

  useEffect(() => {
    // Retrieving data from localStorage
    const storedData = Cookies.get("searchData");
    if (storedData) {
      const searchData = JSON.parse(storedData);
      setBookingData(searchData); // Setting parsed JSON data into bookingData state

    }
  }, []);
  return (
    <div className="container bootdey">
      <div className="col-md-12 ">
        
        <section className="panel my-2 ">
        <h2 className="text-white">Booking Info</h2>
          <div className="panel-body row p-3 rounded">
           
            <div className="col-md-12 col-lg-4 col-sm-12 col-xs-12">
              <div className="pro-img-details p-3">
                <img
                  src={props.property_images}
                  className="w-100 rounded"
                  alt=""
                  style={{
                    height: "250px",
                    width: "250px",
                    objectFit: "cover",
                    transition: 'height 0.3s ease, width 0.3s ease'
                  }}
                  onError={(e) => {
                    e.target.src = "/assets/noimage.png";
                    e.target.alt = "No Image";
                  }}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-8 col-sm-12 col-xs-12 p-3">
              <h2 className="pro-d-title text-gold mb-1">{props.property_name}</h2>
              <p className="text-white mb-1">
                <p className="d-flex align-items m-0">
                  {<FaLocationDot />} {props.property_city}
                </p>
              </p>
              <div className="product_meta row pt-3">
                {/* <div className="col-6 text-white ">
                  <p className="d-flex align-items-center gap-2">
                    <p className="dark p-1 rounded d-flex align-items-center justify-content-center px-2"> 4.6 {" "} <FaStar className="text-gold mx-1" /></p> 
                    <p>(29 ratings) Excellent</p>
                  </p>
                </div> */}

                <div
  className="row text-white pt-4 d-flex justify-content-center"
  style={{ backgroundColor: "#1c2226" }}
>
  {bookingData && (
    <>
   <div
  className="col-lg-12 col-md-12 col-sm-6 col-xs-6 col-6 d-flex flex-wrap justify-content-around align-items-start"
  style={{ marginBottom: "10px" }}
>
  <div className="checkin text-start mb-3">
    <p style={{ margin: "0" }}>Check in</p>
    <p style={{ margin: "0" }}>{bookingData.dateRange.startDate}</p>
  </div>

  <div
    className="divider"
    style={{
      borderLeft: "1px solid #d1b761",
      height: "50px",
      margin: "0 20px",
    }}
  ></div>

  <div className="checkout text-start mb-3">
    <p style={{ margin: "0" }}>Check out</p>
    <p style={{ margin: "0" }}>{bookingData.dateRange.endDate}</p>
  </div>

  <div
    className="divider"
    style={{
      borderLeft: "1px solid #d1b761",
      height: "50px",
      margin: "0 20px",
    }}
  ></div>

  <div className="rooms text-start mb-3">
    <p style={{ margin: "0" }}>Room</p>
    <p style={{ margin: "0" }}>{bookingData.roomCount} Room's</p>
  </div>

  <div
    className="divider"
    style={{
      borderLeft: "1px solid #d1b761 mb-3",
      height: "50px",
      margin: "0 20px",
    }}
  ></div>

  <div className="guest text-start">
    <p style={{ margin: "0" }}>Guest</p>
    <p style={{ margin: "0" }}>{bookingData.adults} P</p>
  </div>
</div>


      
    
    </>
  )}
</div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Bookinginfo;
