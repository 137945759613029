import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    
<div className="container py-5 px-5 mobileresponsive">
<div className="row  rounded bg-chocolate h-100 p-5">
<div className="col-6 d-flex flex-column justify-content-center " >
<h1 className='text-gold'>Oops...</h1>
<h3 className='text-white'>Something went wrong</h3>
<button  className="btn btn-searchbar mt-3 w-25 text-center">
  <Link to="/" className='nav-link'>
  Back to Home
  </Link>
</button>
</div>
<div className="col-6">
<img src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/404error.png" className='w-100' alt="" />
</div>
    </div>
</div>
  )
}

export default Error