// src/components/Preloader.js
import React from 'react';
import { ClipLoader } from "react-spinners";

const Preloader = () => {
  return (
    <div className="container preloader vh-100">
     <ClipLoader color={"#B08E54"} size={50}/>
    </div>
  );
};

export default Preloader;
