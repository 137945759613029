


// import React, { useState } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
// import '../../App.css';

// const ImageChanger = () => {
//   const [activeIndex, setActiveIndex] = useState(0);

//   const handleSelect = (selectedIndex) => {
//     setActiveIndex(selectedIndex);
//   };

//   const images = [
//     // 'assets/banners/mobilemaimoon.jpg',
//     // 'assets/banners/mobilelonavala.jpg',
//     // 'assets/banners/mobilepanchagani.jpg',
//     // 'assets/banners/mobilemontabu.jpg',
//     // 'assets/banners/mobiledumas.jpg',
//     // Add more images as needed
//     'assets/banners/matheran1.jpg',
//     'assets/banners/lonavala.png',
//     'assets/panchagani.png',
//     'assets/banners/mountabu1.jpg',
//     'assets/banners/Dumas1.jpg'
//   ];

//   const linkTexts = ['Matheran', 'Lonavala', 'Panchgani', 'Mount Abu', 'Dumas'];

//   const changeImage = (index) => {
//     setActiveIndex(index);
//   };

//   const linkStyle = (index) => {
//     return {
//       color: activeIndex === index ? '#B69B6C' : '',
//       borderBottom: activeIndex === index ? '4px solid #B69B6C' : 'none',
//     };
//   };

//   return (
//     <div className="w-100 h-100">
//       <Carousel activeIndex={activeIndex} onSelect={handleSelect} indicators={false} className='w-100 h-100'>
//         {images.map((image, index) => (
//           <Carousel.Item key={index} className=''>
//             <img loading="lazy" src={image} alt={linkTexts[index]} className="w-100 h-100" />
//           </Carousel.Item>
//         ))}
//       </Carousel>

//       <div className="p-0 imagelist">
//         <ul className="list-none gap-5 d-flex">
//           {linkTexts.map((linkText, index) => (
//             <li className="nav-item" key={index}>
//               <a
//                 href="#"
//                 onClick={() => changeImage(index)}
//                 className="nav-link imagenavlist"
//                 style={linkStyle(index)}
//               >
//                 {linkText}
//               </a>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default ImageChanger;

import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../../App.css';

const ImageChanger = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [isTab, setIsTab] = useState(window.innerWidth >= 600 && window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
      setIsTab(window.innerWidth >= 600 && window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const getImages = () => {
    if (isMobile) {
      return [
        'assets/banners/mobilemaimoon.jpg',
        'assets/banners/lonavalmobilenew.jpg',
        'assets/banners/mobilepanchagani.jpg',
        'assets/banners/mobilemontabu.jpg',
        'assets/banners/mobiledumas.jpg',
      ];
    } else {
      return [
        '/assets/banners/matheran1.jpg',
        '/assets/banners/lonavalanew.jpg',
        '/assets/panchagani.png',
        '/assets/banners/mountabu1.jpg',
        '/assets/banners/Dumas1.jpg',
      ];
    }
  };

  const images = getImages();
  const linkTexts = ['Matheran', 'Lonavala', 'Panchgani', 'Mount Abu', 'Dumas'];

  const changeImage = (index) => {
    setActiveIndex(index);
  };

  const linkStyle = (index) => {
    return {
      color: activeIndex === index ? '#B69B6C' : '',
      borderBottom: activeIndex === index ? '4px solid #B69B6C' : 'none',
    };
  };

  const carouselStyle = {
    width: '100%',
    height: isMobile ? '450px' : isTab ? '430px' : '700px',
  };

  return (
    <div className="w-100 h-100">
      <Carousel activeIndex={activeIndex} onSelect={handleSelect} indicators={false} className='' style={carouselStyle}>
        {images.map((image, index) => (
          <Carousel.Item key={index} className=''>
            <img src={image} loading="lazy" alt={linkTexts[index]} className="w-100 h-100" />
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="p-0 imagelist">
        <ul className="list-none gap-5 d-flex">
          {linkTexts.map((linkText, index) => (
            <li className="nav-item" key={index}>
              <a
                href="#"
                onClick={() => changeImage(index)}
                className="nav-link imagenavlist"
                style={linkStyle(index)}
              >
                {linkText}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ImageChanger;
