import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = process.env.REACT_APP_BASE_URL;

// Define an initial state
const initialState = {
  myBookings: [],
  loading: false,
  error: null,
};

// Create an async thunk for fetching booking data
export const fetchMyBookings = createAsyncThunk(
  'myBookings/fetchMyBookings',
  async (customerId, { rejectWithValue }) => {
    const token = Cookies.get('token');
    try {
      const response = await axios.post(
        `${apiUrl}/v1/booking/getCustomerBooking`,
        { customer_id: customerId, page: 1 },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a slice of the state
const myBookingsSlice = createSlice({
  name: 'myBookings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyBookings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyBookings.fulfilled, (state, action) => {
        state.loading = false;
        state.myBookings = action.payload;
      })
      .addCase(fetchMyBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default myBookingsSlice.reducer;
