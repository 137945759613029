
// const BlogCard = (props) => {
//   return (
//     <>
//       <img
//         src={props.imageUrl}
//         className={`blog-card-img-top rounded ${props.imageHeight}`}
//         alt={`Card ${props.id}`}
//       />
//       <div className="card-body p-3">
//         <h2 className="Blog-title text-gold text-center">{props.title}</h2>
//         <p className="blog-description mt-3 fs-7 text-center">{props.description}</p>
//         <div className=' d-flex justify-content-center mt-4 '>
//           {props.showButton && (
//             <a href="blogdetail" className="btn btn-searchbar">
//               {/* View More */} {props.button}
//             </a>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default BlogCard;

import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
  return (
    <>
    <Link to={`/blogs/blogdetail/${props.blogId}`}>
    <img
        src={props.imageUrl}
         loading="lazy"
        className={`blog-card-img-top rounded ${props.imageHeight}`}
        alt={`Card ${props.id}`}
      />
            </Link>
      
      <div className="card-body p-3">
        <h2 className="Blog-title text-gold text-center">{props.title}</h2>
        <p className="blog-description mt-3 fs-7 text-center">{props.description}</p>
        <div className=' d-flex justify-content-center mt-4 '>
          {props.showButton && (
            <Link to={`/blogs/blogdetail/${props.blogId}`} className="btn btn-searchbar">
              {props.button}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogCard;

