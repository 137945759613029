import React, { useState } from 'react';
import CancelPolicy from "../Popups/CancelPolicy";
import { FaLocationDot, FaStar } from "react-icons/fa6";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Carousel } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRoomUid } from '../../redux/room/roomSlice';


const ProductCard = (props) => {
  const { room_type, roomId, room_charge, base_guest_occupancy,max_guest_occupancy, extra_bed_count,propertyId, roomImages, availableRooms, filtered, room_name } = props;
  const { amenityIcons } = props;
  const [show, setShow] = useState(false);
  const selectedRoomUid = useSelector((state) => state.room.selectedRoomUid);
  const dispatch = useDispatch()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="col-md-12 ">
      <section className="panel my-5 mx-5 mobileresponsive px-5">
        <div className="panel-body row p-lg-3 p-md-3 p-sm-3 p-sx-2 rounded">

          <div className="col-md-12 col-lg-5 col-sm-12 col-xs-12 pt-lg-3 pt-md-3 pt-sm-3 pt-xs-0  ">

            <div className="pro-img-details px-lg-3 px-md-3 px-sm-3 px-xs-0 mt-3 booked">
              {props.is_booked && (
                <div className="booked-overlay d-flex align-items-center justify-content-center">
                  <p className="text-white fs-5 booking-rotate">Sold Out</p>
                </div>
              )}

              <img
                src={props.image}
                className="d-block w-100 rounded my-3 "
                 loading="lazy"
                alt=""
                style={{
                  height: "280px",
                  width: "280px",
                  objectFit: "cover",
                  transition: 'height 0.3s ease, width 0.3s ease'
                }}
                onError={(e) => {
                  e.target.src = "/assets/noimage.png";
                  e.target.alt = "No Image";
                }}
              />
            </div>
            <div className="topline mt-2"></div>

            <div className="images gap-2 d-flex flex-wrap px-lg-3 px-md-3 px-sm-3 px-xs-0  pt-3">
              {/* Your existing images */}
              <img src={props.image0 ? props.image0 : ""} className="bookingimages rounded " alt="" />
              <img src={props.image1 ? props.image1 : ""} className="bookingimages rounded " alt="" />
              <div className="bookingimages rounded position-relative" onClick={handleShow}>
                <img src={props.image2 ? props.image2 : ""} className="bookingimages w-100 rounded" alt="" />
                <div className="position-absolute top-50 start-50 translate-middle bg-transparentblack text-white w-100 h-100 d-flex align-items-center justify-content-center" >
                  <p className='cursor-pointer'>View all</p>

                </div>

              </div>
            </div>

            <Modal show={show} onHide={handleClose} centered >

              <Modal.Header >

                <Modal.Title><h3>Rooms Gallery</h3></Modal.Title>
                <IoClose
                  className="text-white fs-3 cursor-pointer" // Added "text-white" class here
                  onClick={handleClose}
                  aria-label="Close"
                />
              </Modal.Header>
              <Modal.Body className='d-flex flex-wrap gap-3 overflow-auto roomscrollbar '>



                {props.roomImages && props.roomImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}

                    className="d-block imagegallery rounded"
                    alt={`Gallery ${index + 1}`}
                  />
                ))}


              </Modal.Body>
              <Modal.Footer className="border-0 ">
                <button
                  className="btn-rounded pX-3 py-2 btn-gold text-black"
                  type="button"
                >
                  <NavLink
                    className="nav-link"
                    to={`/home/productdetail/${props.property_name}/${props.property_city}/${props.propertyId}/${props.roomId}/${props.is_booked}`}
                    state={{ availRooms: filtered, test: "gsgs" }}
                  // to={`/productdetail/${props.property_name}/${props.property_city}/${props.propertyId}/${props.roomId}`}
                  >
                    View Detail
                  </NavLink>
                </button>

              </Modal.Footer>
            </Modal>

            {/* <div className={`modal fade ${show ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{ height: "500px" }}>
            <div className="modal-header">
              <h5 className="modal-title text-danger" id="exampleModalLabel">PDF</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              {props.roomImages && props.roomImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  onError={handleImageError}
                  className="d-block imagegallery rounded"
                  alt={`Gallery ${index + 1}`}
                />
              ))}
            </div>
            <div className="modal-footer">
              <button
                className="btn-rounded pX-3 py-2 btn-gold text-black"
                type="button"
              >
                <NavLink
                  className="nav-link"
                  to={`/productdetail/${props.property_name}/${props.property_city}/${props.propertyId}/${props.roomId}/${props.is_booked}`}
                  state={{ availRooms: filtered, test: "gsgs" }}
                  // to={`/productdetail/${props.property_name}/${props.property_city}/${props.propertyId}/${props.roomId}`}
                >
                  View Detail
                </NavLink>
              </button>
            </div>
          </div>
        </div>
      </div> */}


          </div>
          <div className="col-md-12 col-lg-7 col-sm-12 col-xs-12 pt-3">
            <div className=" head d-flex flex-wrap justify-content-between align-items-center mt-3 px-2 px-sm-2 px-md-2 px-lg-0">
              <h2 className="pro-d-title text-gold m-0 ">{props.room_type}</h2>
              <CancelPolicy />
            </div>
            <p className="text-white d-flex align-items-center mb-1  ">
              <p className="m-0 mobileresponsive"> {props.property_name}</p>{" "}
              <p className="px-2 d-flex align-items-center m-0">
                {<FaLocationDot className="pe-1" />} {props.property_city}
              </p>
            </p>
            <div className="product_meta row ">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-white  ">
                {" "}
                <p className=" dimension p-2 p-sm-2 p-md-2 p-lg-3  rounded-2 my-lg-3 my-md-0">
                  Room Size : {props.room_size} | Bed Size :{" "}
                  {props.bed_size}
                </p>
                <div className="booking_amenities text-white">
                  Offered Amenities
                  <div className="row my-2 mobileresponsive">
                    <div className="col-lg-6 col-md-6 col-sm-5 col-xs-5 col-5 ">
                      <ul className="amenities-list p-0 mb-0 text-white">
                        {props.amenities.slice(0, 3).map((amenity, index) => (
                          <li
                            key={index}
                            className="amenity-item gap-2 d-flex align-items-center text-white"
                          >
                            {/* Assuming amenity_icon should be used here */}
                            {amenity.amenity_icon && (
                              <img
                                src={amenity.amenity_icon}
                                alt={amenity.amenity_name}
                                className="icon property_icons text-white roomaminities mb-2"
                              // Add necessary styles for the icon
                              />
                            )}
                            <span>{amenity.amenity_name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-5 col-xs-5 col-5 ">
                      <ul className="amenities-list mb-0">
                        {props.amenities.slice(3, 6).map((amenity, index) => (
                          <li
                            key={index}
                            className="amenity-item gap-2 d-flex align-items-center text-white" // Add text-white class here
                          >
                            {amenity.amenity_icon && (
                              <img
                                src={amenity.amenity_icon}
                                alt={amenity.amenity_name}
                                className="icon property_icons text-gold roomaminities mb-2" // Add text-white class here as well if needed
                              />
                            )}
                            <span>{amenity.amenity_name}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center bookingprice1">

                <div className="room_type text-white ">
                  <div>
                  <p className="text-gold mb-1 ">Max capacity of {props.max_guest_occupancy}</p>
                  <p>Room Price</p>
                  <h2 className='m-0'>₹ {props.room_charge}/ N </h2>
                  <p className='for-two-person m-0'>up to {props.base_guest_occupancy} persons</p>
                  {!props.is_booked && <p className='text-white pt-3'>Available Rooms : {props.availableRooms}</p>}
                  </div>

                </div>

              </div>
            </div>
            <div className="topline mt-2"></div>
            <div className="gap-3 d-flex justify-content-end  pt-lg-4 pt-md-4 pt-sm-4 pt-xs-2 pb-2 mt-2">

              {props.showButton && (
                <button
                  className="btn-rounded px-3 btn-gold text-black"
                  type="button"
                >
                  <Link
                    className="nav-link"
                    to={`/home/productdetail/${props.property_name}/${props.property_city}/${props.propertyId}/${props.roomId}/${props.is_booked}`}
                    state={{ availRooms: filtered, test: "gsgs" }}
                  >
                    View Detail
                  </Link>
                </button>
              )}

              {props.is_booked ? (
                <button className="btn btn-booked" disabled type="button" onClick={() => {
                  // Show tooltip/notification when the "Not Available" button is clicked
                  toast.error('Room already booked', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000, // Duration for which the toast will be shown (in milliseconds)
                  });
                }}>
                  <NavLink className="nav-link" >Add Room</NavLink>
                </button>
              ) : (
                <button
                  className="eg-btn btn btn-searchbar"
                  type="button"
                  onClick={() => {
                    dispatch(setSelectedRoomUid({ room_type, room_name, roomId,base_guest_occupancy, base_room_charge: Number(room_charge), propertyId, availableRooms, initialCount: 1,max_guest_occupancy,base_extra_bed:extra_bed_count,extra_services:[],initialBedCount:0}))
                  }}
                >
                  <NavLink className="nav-link">Add Room</NavLink>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductCard;
