import React, { useRef, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSelector, useDispatch } from "react-redux";
import "../../App.css"; // Import your CSS file for styling
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import {
  setLocation,
  setLoading,
  fetchProperties,
  fetchPropertyLocations,
} from "../../redux/Property/propertySlice";
import { NavLink } from "react-router-dom";

const Tourplaces = () => {
  const dispatch = useDispatch();
  const { properties, location, locations } = useSelector((state) => state.property);

  useEffect(() => {
    // Dispatch the fetchProperties action with the current location
    dispatch(fetchProperties(location));
    dispatch(fetchPropertyLocations());
  }, [dispatch,]);




  const handleOnDragStart = (e) => e.preventDefault();
  const carouselRef = useRef(null);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const slidePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const slideNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  return (
    <div className="container">
      <div className="row text-white">
        <div className="col">
          <span className="mx-5 px-5 mobileresponsive">Have a look</span>
          <div className='d-flex justify-content-start align-items-center gap-4 '>
            <img src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/line.svg" className="linehome  " alt="image" />

            <h1 className="ps-2">Find Your Desired Places</h1>

          </div>
          {/* <p className="mx-5 px-5 m-0 mobileresponsive">Voluptate exercitation incididunt aliquip deserunt duis aute irure dolor.</p> */}
        </div>
      </div>
      <div className="carousel-wrapper">
        <button onClick={slidePrev} className="arrow-btn prev">
          <FiChevronLeft className="fs-1 arrowleft" />
        </button>
        <AliceCarousel
          mouseTracking
          responsive={responsive}
          disableButtonsControls
          disableDotsControls
          ref={carouselRef}
        >
          {locations?.data?.map((card, index) => (
            <div key={index} className="item">
              <div className="row px-2 ">
                <div className="tourcard bgcard">
                  <img
                    className="tourimage rounded"
                    src={card.image
                    }
                    alt="Card"
                    onDragStart={handleOnDragStart}
                  // Set the height to 250px for mobile view
                  />
                  <div className="card-body my-4">
                    <h2 className="card-title text-gold">{card.city}</h2>
                    <p className="fs-7 mt-2 text-white mb-3">
                      {card.description &&
                        card.description
                          .split(' ')
                          .slice(0, 15)
                          .join(' ')}
                      {card.description?.split(' ').length > 15 && '...'}
                    </p>
                    <NavLink to={`/destinations/propertiedetail/${card.city}`} className="btn eg-btn btn">
                      View More
                    </NavLink>

                  </div>
                </div>
              </div>
            </div>
          ))}
        </AliceCarousel>
        <button onClick={slideNext} className="arrow-btn next d-flex align-items-center">
          <FiChevronRight className="fs-1 arrowright" />
        </button>
      </div>
    </div>
  );
};

export default Tourplaces;
