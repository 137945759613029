import React from "react";
import CancelPolicy from "../Popups/CancelPolicy";
import { FaArrowRightLong, FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import { setSelectedRoomUid ,setSelectedPropertyId} from "../../redux/room/roomSlice";
import { useDispatch, useSelector } from "react-redux";

const PropertyCard = (props) => {
  const { amenityIcons } = props;
  const navigate = useNavigate();
  const {userSelectedPropertyId}=useSelector((state)=>state.room)
  const dispatch=useDispatch();
  const handleNav = () => {
    navigate(`/home/bookings/${props.propertyId}`);
    if(userSelectedPropertyId !== props.propertyId){
      dispatch(setSelectedRoomUid({}));
    }
    dispatch(setSelectedPropertyId(props.propertyId));

  }
  return (
    <div className="col-md-12">
      <section className="panel my-5 mx-5 mobileresponsive px-5">
        <div className="panel-body row p-2 rounded">
          <div className="col-md-12 col-lg-4 col-sm-12 col-xl-4 col-xs-12">
            <div className="pro-img-details px-2 mt-4">
              
              <img
                      src={props.image}
                      className="d-block w-100 rounded mt-3 mb-lg-3 mb-md-1 mb-sm-1 mb-xs-1 "
                       loading="lazy"
                      alt=""
                      style={{
                        height: "250px",
                        width: "250px",
                        objectFit: "cover",
                        transition: 'height 0.3s ease, width 0.3s ease'
                      }}
                      onError={(e) => {
                        e.target.src = "/assets/noimage.png";
                        e.target.alt = "No Image";
                      }}
                    />
            </div>
          </div>
        <div className="col-md-12 col-lg-8 col-sm-12 p-3">
            <div className="head d-flex justify-content-between pt-lg-3 pt-md-0 pt-sm-0 pt-xs-0">
              <h2 className="pro-d-title text-gold m-0">
                {props.property_name}
              </h2>
            </div>
            <p className="text-white d-flex align-items-center mb-1">
              <FaLocationDot className="pe-1" />
              {props.property_city}
            </p>
            <div className="product_meta row p-0">
              <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 text-white">
                <p className="text-gold mb-1">{props.max_capacity}</p>
                <p className="d-flex align-items-center gap-2 my-2">
                  {/* <p className="dark p-1 rounded d-flex align-items-center m-0 ">
                    {" "}
                    {props.property_rating}{" "}
                   4.6 <FaStar className="text-gold mx-2" />
                  </p> */}
                </p>
                <p className="my-1 dimension p-3 mt-2 rounded-2">
                  Total Rooms : {props.total_rooms} | Size : {props.property_size}
                </p>
                {/* <div className="tagged_as my-2">
                  {props.tags.map((tag, index) => (
                    <span key={index} className="fs-7">
                      <a
                        rel="tag"
                        className="text-white tags px-3 fs-7"
                        href="#/"
                      >
                        {tag}
                      </a>
                    </span>
                  ))}
                </div> */}
              </div>

              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 booking_amenities text-white">
  Offered Amenities
  <div className="row my-2">
  <div className="col-lg-6 col-md-6 col-sm-6 col-5"> {/* Adjusting the small device sizes */}
    <ul className="amenities-list m-0">
      {props.indoor_amenities.slice(0, 3).map((amenity, index) => (
        <li
          key={index}
          className="amenity-item gap-2 d-flex align-items-center "
        >
          {amenity.amenity_icon && (
            <img
              src={amenity.amenity_icon}
              alt={amenity.amenity_name}
              className="icon property_icons roomaminities mb-2"
            />
          )}
          <span>{amenity.amenity_name}</span>
        </li>
      ))}
    </ul>
  </div>
  <div className="col-lg-6 col-md-6 col-sm-6 col-5"> {/* Adjusting the small device sizes */}
    <ul className="amenities-list m-0">
      {props.indoor_amenities.slice(3, 6).map((amenity, index) => (
        <li
          key={index}
          className="amenity-item gap-2 d-flex align-items-center "
        >
          {amenity.amenity_icon && (
            <img
              src={amenity.amenity_icon}
              alt={amenity.amenity_name}
              className="icon property_icons roomaminities mb-2"
            />
          )}
          <span>{amenity.amenity_name}</span>
        </li>
      ))}
    </ul>
  </div>
</div>

</div>

            </div>
          </div>
          <div className="topline mt-0"></div>
          <div className="gap-3 d-flex justify-content-end align-items-center py-2">
            <button className="eg-btn btn btn-searchbar " onClick={handleNav}>
                View Rooms <FaArrowRightLong className="ps-2 fs-4" />
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PropertyCard;
