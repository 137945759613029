import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import { ProgressBar, Step } from "react-step-progress-bar";

const RefundScreen = () => {
  const products = [
    {
      id: 1,
      name: "Standard Room",
      location: "Maimoon villa ",
      amount: " 3780",
      guest: "1",
      room: "2",
      bookingid: "8520",
      image: "assets/bookingimg.png",
    },

    // Add more products here if needed
  ];

  return (
    <div className="refund mt-5 pt-5 container ">
      <h1 className="text-white px-5 mx-5 mobileresponsive">
        Your booking has been cancelled
      </h1>
      <div className="container bootdey">
        {products.map((product) => (
          <div className="col-md-12 " key={product.id}>
            <section className="panel my-5 mx-5 mobileresponsive px-5">
              <div className="panel-body row p-3 rounded">
                <div className="col-md-4">
                  <div className="pro-img-details px-3 my-3 ">
                    <img src={product.image} className="w-100 rounded" alt="" />
                  </div>
                </div>
                <div className="col-md-8 p-3 text-white">
                  <div className="head d-flex justify-content-between  align-items-center mt-3">
                    <h2 className="pro-d-title text-gold m-0">
                      {product.name}
                    </h2>
                    <div className="d-flex flex-column cursor-pointer ">
                      <p className="text-white">
                        Booking ID : {product.bookingid}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-white d-flex align-items-center mb-1 ">
                      <p className="m-0"> {product.location}</p>{" "}
                      <p className="px-2 d-flex align-items-center m-0">
                        {<FaLocationDot className="pe-1" />} Matheran
                      </p>
                    </div>
                    <div className="d-flex justify-content-end">
                      <p className="text-center">Need Help?</p>
                    </div>
                  </div>

                  <div className="product_meta row">
                    <div className="d-flex gap-4 align-items-center pt-3">
                      <SlCalender className="fs-1 " />
                      <div className="d-flex flex-column ">
                        <p className="fs-4">Nov 20,2022 - Nov 22,2022</p>
                        <p>2 Guests, 1 Room</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ))}
      </div>

      <div className="container status px-5 mobileresponsive">
        <div className="bg-chocolate rounded  mx-5 px-5 mobileresponsive text-white py-3">
          <p className="text-gold fs-1">Refund status</p>
          <p>Total refund initiated : 1402.4</p>
          <p className="fs-5 pt-3">
            You cancelled Maimoon viall. Refund of ₹ 1402 in progress
          </p>

          {/* Step progress Bar */}
          <section className="step-wizard d-flex justify-content-start w-100">
            <ul className="step-wizard-list">
              <li className="step-wizard-item">
                <span className="progress-count">1</span>
                <span className="progress-label">Billing Info</span>
              </li>
              <li className="step-wizard-item ">
                <span className="progress-count">2</span>
                <span className="progress-label">Payment Method</span>
              </li>
              <li className="step-wizard-item current-item">
                <span className="progress-count">3</span>
                <span className="progress-label">Checkout</span>
              </li>
            </ul>
          </section>
          <p className="fs-5">
            Refund of ₹1402.04 with RRR number 13312730799 has been processed to
            your account
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundScreen;
