import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { NavLink } from "react-router-dom";

const About_Properties = (props) => {

  const aboutContent = {
    heading: "Matheran",
    
    images: [
      { id: 1, src: "assets/MatheranP1.png", alt: "Image 1" },
      { id: 2, src: "assets/property2.png", alt: "Image 2" },
      { id: 3, src: "assets/property3.png", alt: "Image 3" },
    ],
  };

  
  return (
    <div className="container mt-5 pt-5 text-white">
      <div className="row ">
        <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12 ">
          <div className="d-flex  justify-content-start align-items-center my-3">
            <img
              src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/line.svg"
              className="linehome mx-3"
              alt="image"
            />
            <h2 className="fs-1 ps-2">About {props.property_name}</h2>
          </div>
          <div className="mx-5 ps-5 mobileresponsive">
            <p className="p-0 mb-3  text-white">{props.property_description}</p>
            {/* <button className="eg-btn btn btn-searchbar ">
              <NavLink className="nav-link" to={`/home/bookings/${props.propertyId}`}>
                View Rooms <FaArrowRightLong className="ps-2 fs-4" />
              </NavLink>
            </button> */}
          </div>
        </div>
        <div className="col-md-12 col-lg-5 col-sm-12 col-xs-12 ">
          <div className="row">
            <div className="col-md-12 mb-3">
                   <img
                      src={props.property_images[1]}
                      className="d-block w-100 rounded "
                      alt={aboutContent.images[0].alt}
                      style={{
                        height: "250px",
                        width: "250px",
                        objectFit: "cover",
                        transition: 'height 0.3s ease, width 0.3s ease'
                      }}
                      onError={(e) => {
                        e.target.src = "/assets/noimage.png";
                        e.target.alt = "No Image";
                      }}
                    />
            </div>
            <div className="col-md-6 mb-2">
              <div className="card bg-transparent">
               
                 <img
                      src={props.property_images[2]}
                      className=" w-100 rounded"
                      alt={aboutContent.images[1].alt}
                      style={{
                        height: "160px",
                        width: "240px",
                        objectFit: "cover",
                        transition: 'height 0.3s ease, width 0.3s ease'
                      }}
                      onError={(e) => {
                        e.target.src = "/assets/noimage.png";
                        e.target.alt = "No Image";
                      }}
                    />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="card bg-transparent">
                   <img
                      src={props.property_images[3]}
                      className="w-100 rounded"
                      alt={aboutContent.images[2].alt}
                      style={{
                        height: "160px",
                        width: "250px",
                        objectFit: "cover",
                        transition: 'height 0.3s ease, width 0.3s ease'
                      }}
                      onError={(e) => {
                        e.target.src = "/assets/noimage.png";
                        e.target.alt = "No Image";
                      }}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About_Properties;
