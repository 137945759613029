
import React, { useState, useEffect } from "react";
import '../../App.css';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useSelector } from "react-redux";

const Guest_Details_form = (props) => {
  const { customerDetails, customerStatus, customerError } = useSelector((state) => state.auth);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handlePhoneChange = (value) => {
    setMobileNumber(value);
  };

  const validatePhone = () => {
    // Allow for spaces between digits
    const phoneRegex = /^\+\d{2}\s?\d{4}\s?\d{3}\s?\d{3}$/;
    if (!phoneRegex.test(mobileNumber)) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  };

  const validateEmail = () => {
    // Replace this regular expression with the desired email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    props.onGuestDetails({ name, email, mobileNumber, gstNumber, companyName, message });
  }, [name, email, mobileNumber, gstNumber, companyName, message]);

  return (
    <div className="orderform px-4 py-3 rounded">
      <p className="text-white mb-3 ">Guest Details</p>
      <form>
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-3 ">
            <label htmlFor="nameInput" className="form-label text-white">Name *</label>
            <input
              type="text"
              className="form-control  payment_form"
              style={{ padding: "10px" }}
              id="nameInput"
              defaultValue={customerDetails?.fullName}

              required
            />
          </div>

        </div>
        <div className="col-md-12 col-sm-12 mb-3">
          <label htmlFor="phoneInput" className="form-label text-white">Phone Number *</label>
          <PhoneInput
            international
            defaultCountry="IN"
            className={`form-control payment_form no-border ${phoneError ? 'is-invalid' : ''}`}
            style={{ padding: '10px' }}
            value={customerDetails?.mobile_no || ''}
            inputStyle={{ width: '100%' }}
            onChange={(phone) => handlePhoneChange(phone)} // Ensure you have an onChange handler
          />
          {phoneError && <div className="invalid-feedback">{phoneError}</div>}
        </div>
        <div className="col-md-12 col-sm-12 mb-3">
          <label htmlFor="emailInput" className="form-label text-white">Email *</label>
          <input
            type="email"
            className={`form-control  payment_form ${emailError ? 'is-invalid' : ''}`}
            style={{ padding: "10px" }}
            id="emailInput"
            defaultValue={customerDetails?.email}

            onBlur={validateEmail}
            required
          />
          {emailError && <div className="invalid-feedback">{emailError}</div>}
        </div>

        {/* <div className="mb-3">
          <label htmlFor="messageInput" className="form-label text-white">Purpose of visit</label>
          <textarea
            className="form-control payment_form2"
            id="messageInput"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div> */}
        <div className="row pt-2 pb-5">
          <div className="col-md-6 col-sm-12 ">
            <label htmlFor="companyInput" className="form-label text-white">Company Name</label>
            <input
              type="text"
              className="form-control  payment_form"
              id="companyInput"
              style={{ padding: "10px" }}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <label htmlFor="gstInput" className="form-label text-white">GST Number</label>
            <input
              type="text"
              className="form-control  payment_form"
              id="gstInput"
              style={{ padding: "10px" }}
              value={gstNumber}
              onChange={(e) => setGstNumber(e.target.value)}
              required
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default Guest_Details_form;
