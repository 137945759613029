import React, { useState } from "react";
import "../../App.css";
import { FaStar} from "react-icons/fa";
import { FaLocationDot, FaArrowRightLong } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import Event_Searchbar from "./Event_Searchbar";
import CancelPolicy from "../Popups/CancelPolicy";

const EventRooms = () => {
  const products = [
    {
      id: 1,
      name: "Yuqoot Suite",
      location: "Matheran ",
      description: "Max capacity of 100  ",
      dimension: "99mx99m | Area :  7000 sq.ft | Height : 3m",
      image: "assets/eventrooms.png",
      price: "Rs 6500/N",
    },

    {
      id: 2,
      name: "Yuqoot Suite",
      location: "Matheran",
      description: "Max capacity of 100  ",
      rating: "4.6  ( 29 ratings ) Excellent",
      dimension: "99mx99m | Area :  7000 sq.ft | Height : 3m",
      image: "assets/eventrooms.png",
      price: "Rs 6500/N",
    },
    // Add more products here if needed
  ];

  return (
    <>
      <div className="my-3 mx-5 px-5 mobileresponsive">
        <Event_Searchbar name="eventroom" />
      </div>
      <div>
        <div className="container bootdey">
          {products.map((product) => (
            <div className="col-md-12 " key={product.id}>
              <section className="panel my-5 mx-5 mobileresponsive px-5">
                <div className="panel-body row p-3 rounded">
                  <div className="col-md-4">
                    <div className="mt-3 px-3 ">
                      <img
                        src={product.image}
                        className="w-100 rounded"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-8 p-3">
                    <div className="head d-flex justify-content-between mt-3">
                      <h2 className="pro-d-title text-gold m-0">
                        {product.name}
                      </h2>
                      <CancelPolicy />
                    </div>
                    <p className="text-white d-flex align-items-center mb-1 ">
                      <p className="m-0 d-flex align-items-center">
                        {<FaLocationDot className="pe-1" />} {product.location}
                      </p>{" "}
                    </p>
                    <div className="product_meta row">
                      <div className="col-6 text-white ">
                        {" "}
                        <p className="text-gold mb-1">{product.description}</p>
                        <p className="d-flex align-items-center gap-2 my-2">
                          {" "}
                          <p className="dark p-1 rounded d-flex align-items-center m-0 ">
                            {" "}
                            4.6 <FaStar className="text-gold mx-2" />
                          </p>
                          <p className="m-0">( 29 ratings ) Excellent</p>
                        </p>
                        <p className="my-1 dimension p-2 rounded-2">
                          Dimension : {product.dimension}
                        </p>
                      </div>
                      <div className="col-6 booking_amenities text-white d-flex justify-content-end align-items-end ">
                        <div className="gap-3 d-flex mt-4">
                          <button
                            className="btn btn-round btn-light text-white"
                            type="button"
                          >
                            <NavLink className="nav-link" to="/productdetail">
                              View Details
                            </NavLink>
                          </button>
                          <button className="eg-btn btn btn-searchbar ">
                            {" "}
                            <NavLink className="nav-link" to="/home/orderdetail">
                              {" "}
                              Book Now
                            </NavLink>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EventRooms;
