// import { createSlice } from "@reduxjs/toolkit";

// const counterSlice=createSlice({
//     name:'counter',
//     initialState:{count:0},
//     reducers:{
//         increment:(state,action)=>{
//             state.count+=1
//         },
//         decrement:(state,action)=>{
//             state.count-=1
//         }

//     }
// })
// export const {increment,decrement}=counterSlice.actions;
// export const counterslice= counterSlice.reducer;
// const roomSlice = createSlice({
//   name: "room",
//   initialState: {
//     cartLength: 0,
//     selectedRoomUid: [],
//   },
//   reducers: {
//     setSelectedRoomUid(state, action) {
//       state.selectedRoomUid = [...state.selectedRoomUid, action.payload];
//       state.cartLength = state.cartLength + 1;
//     },
//     removeSelectedRoomUid(state, action) {
//       const roomUidToRemove = action.payload;
//       state.selectedRoomUid = state.selectedRoomUid.filter(
//         (room) => room.roomId !== roomUidToRemove
//         );
//         state.cartLength = state.cartLength - 1;
      
//     },
//     clearSelectedRooms(state) {
//       state.selectedRoomUid = [];
//     },
//   },
// });

// export const { setSelectedRoomUid, removeSelectedRoomUid } = roomSlice.actions;
// export default roomSlice.reducer;

// const orderDetailSlice = createSlice({
//     name: "orderdetail",
//     initialState: {
//       orderPrice: 0,
//       orderTax:0
//     },
//     reducers: {
//       setOrderPrice: (state, action) => {
//         state.orderPrice = action.payload.price;
//       },
//       setOrderTax: (state, action) => {
//         state.orderTax = action.payload.tax;
//       },
//     },
//   });
  
// export const { setOrderPrice,setOrderTax } = orderDetailSlice.actions;
// export const orderDetail= orderDetailSlice.reducer;


// rootReducer.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ToastContainer, Bounce, toast } from "react-toastify";

import axios from "axios";
import moment from "moment";
import { IoClose } from "react-icons/io5";
import Cookies from "js-cookie";

// Counter Slice
const counterSlice = createSlice({
  name: 'counter',
  initialState:{ count:0 },
  reducers: {
    increment: (state, action) => {
      state.count += 1;
    },
    decrement: (state, action) => {
      state.count -= 1;
    }
  }
});

export const { increment, decrement } = counterSlice.actions;
export const counterReducer = counterSlice.reducer;

export const fetchRoomData = createAsyncThunk(
  'room/fetchRoomData',
  async (payload, thunkAPI) => {
    try {
      const { propertyId, bookingData } = payload;

      // Check if bookingData is available
      if (!bookingData || !bookingData.dateRange || !bookingData.dateRange.startDate || !bookingData.dateRange.endDate) {
        console.error("Booking data or date range is missing or invalid.");
        return null;
      }

      const formattedCheckin = moment(bookingData.dateRange.startDate, "DD MMM YY").format("YYYY-MM-DD");
      const formattedCheckout = moment(bookingData.dateRange.endDate, "DD MMM YY").format("YYYY-MM-DD");

      const obj = {
        startDate: formattedCheckin,
        endDate: formattedCheckout,
        adults: bookingData.adults,
        children: bookingData.children,
        no_of_rooms: bookingData.roomCount,
      };

      const token = Cookies.get('token'); // Retrieve the token from cookies

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/property/room/get-rooms-info/${propertyId}`,
        obj,
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Add token to headers
          }
        }
      );

      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);


const roomSlice = createSlice({
  name: 'room',
  initialState: {
    userSelectedPropertyId:null,
    cartLength: 0,
    selectedRoomUid: [],
    loading: false,
    error: null,
    availableRooms: [],
    soldOutRooms: [],
    propertyData:{}
  },
  reducers: {
    setSelectedRoomUid(state, action) {
      if(Object.keys(action.payload).length>0){
        const obj=state.selectedRoomUid.find(obj=>obj.roomId===action.payload.roomId);
        if(!obj){
          state.selectedRoomUid=[...state.selectedRoomUid,action.payload];
          state.cartLength=state.cartLength+1;
          toast.success("Room added to cart!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: {
              background: '#1e2526', // Set background color to black
              color: 'white', // Set text color to white
            },
            progressStyle: {
              background: '#b69b6c', // Set progress bar color to gold
            },
            closeButton: <IoClose color="white" />, // Set close button to white
            closeOnClick: true, 
          });
        }else{
          toast.warning("Room already in cart!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: {
              background: '#1e2526', // Set background color to black
              color: 'white', // Set text color to white
            },
            progressStyle: {
              background: '#b69b6c', // Set progress bar color to gold
            },
            closeButton: <IoClose color="white" />, // Set close button to white
            closeOnClick: true, 
          });
        }
      }else{
        state.cartLength=0;
        state.selectedRoomUid=[];
      }
      
    },
    removeSelectedRoomUid(state, action) {
      const {roomId,count} = action.payload;
      state.selectedRoomUid = state.selectedRoomUid.filter(
        (room) => room.roomId !== roomId
      );
      state.cartLength = state.cartLength - count;
    },
    clearSelectedRooms(state) {
      state.selectedRoomUid = [];
    },
    updateSelectedRoomUid(state, action) { 
      const {count,id,name,extra_ss}=action.payload;
      console.log(count,id,name,extra_ss);
      state.selectedRoomUid=state.selectedRoomUid.map(room=>{
        if(room.roomId === id && name==="room"){
          if(count < room.availableRooms){
          room.initialCount=room.initialCount+1;
          room.max_base_guest_occupancy = room.base_guest_occupancy * room.initialCount;
          room.max2_guest_occupancy = room.max_guest_occupancy * room.initialCount;
          room.room_charge = room.base_room_charge * room.initialCount;
          room.max_base_extra_bed=room.base_extra_bed * room.initialCount;
          state.cartLength = state.cartLength + 1;
         }         
          return room
        }else if(room.roomId === id && name==="bed" && extra_ss){
          if(count <= room.initialBedCount){
            room.initialBedCount=room.initialBedCount + 1;
            room.extra_services=[{...extra_ss, "service_count":room.initialBedCount}]
          }
          return room
        }
        return room
      })
      
      
    },
    decrementSelectedRoomUid(state,action){
      const {count,id,name,extra_ss}=action.payload;
      state.selectedRoomUid=state.selectedRoomUid.map(room=>{
        if(room.roomId === id && name==="room"){
          if(count <= room.availableRooms){
          room.initialCount=room.initialCount-1
          room.max_base_guest_occupancy = room.base_guest_occupancy * room.initialCount;
          room.max2_guest_occupancy = room.max_guest_occupancy * room.initialCount;
          room.room_charge = room.base_room_charge * room.initialCount;
          room.max_base_extra_bed=room.base_extra_bed * room.initialCount;
          state.cartLength = state.cartLength - 1;
           }
         
          
          return room
        }else if(room.roomId === id && name==="bed" && extra_ss){
          if(count===1 && room.initialBedCount===1){
            room.initialBedCount=room.initialBedCount-1;
            room.extra_services=[]
          }else if(count <= room.initialBedCount){
            room.initialBedCount=room.initialBedCount-1;
            room.extra_services=[{...extra_ss, "service_count":room.initialBedCount}]
          }
          return room
        }
        return room
      })
    },
    setSelectedPropertyId(state,action){
      state.userSelectedPropertyId=action.payload

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoomData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoomData.fulfilled, (state, action) => {
        // Divide the rooms into availableRooms and soldOutRooms
        const { availableRooms, soldOutRooms ,propertyData} = action.payload;
        state.availableRooms = availableRooms||[];
        state.soldOutRooms = soldOutRooms||[];
        state.propertyData=propertyData;
        state.loading = false;
      })
      .addCase(fetchRoomData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setSelectedRoomUid, removeSelectedRoomUid, clearSelectedRooms, updateSelectedRoomUid ,decrementSelectedRoomUid,setSelectedPropertyId,updateSelectedRoomUidBeds} = roomSlice.actions;
export const roomReducer = roomSlice.reducer;

// Order Detail Slice
const orderDetailSlice = createSlice({
  name: 'orderdetail',
  initialState: {
    orderPrice: 0,
    extraBedPrice:0,
    orderTax: 0,
    highestSelectedRoomPrice:0
  },
  reducers: {
    setOrderPrice: (state, action) => {
      state.orderPrice = action.payload.price;
    },
    setOrderTax: (state, action) => {
      state.orderTax = action.payload.tax;
    },
    setExtraBedPrice:(state,action)=>{
      state.extraBedPrice=action.payload.bedPrice  
    },
    setHighestSelectedRoomPrice:(state,action)=>{
      state.highestSelectedRoomPrice=action.payload.highestPrice  
    }
  },
});

export const { setOrderPrice, setOrderTax,setExtraBedPrice,setHighestSelectedRoomPrice } = orderDetailSlice.actions;
export const orderDetailReducer = orderDetailSlice.reducer;

