import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";

const CancelPolicy = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <p className="text-green cursor-pointer" onClick={toggleModal}>
        Cancellation policy
      </p>

      {showModal && (
        <div
          className="modal d-flex justify-content-center align-items-center"
          tabIndex="-1"
          role="dialog"
          style={{
            display: "block",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div
            className="modal-dialog rounded"
            role="document"
            style={{ maxWidth: "600px", width: "100%" }}
          >
            <div className="modal-content px-2 pt-2">
              <div className="modal-header pt-2">
                <h2 className="modal-title text-center cursor-pointer fs-4">
                  Cancellation Policy
                </h2>
                <p type="button" className="close" onClick={toggleModal}>
                  <IoMdClose className="fs-4" />
                </p>
              </div>
              <div
                className="modal-body fs-7"
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  scrollbarWidth: "thin", // For Firefox
                  scrollbarColor: "#b08e54 transparent", // For Firefox
                }}
              >
                <style>
                  {`
                  /* Custom scrollbar for Webkit-based browsers (Chrome, Safari, Edge) */
                  .modal-body::-webkit-scrollbar {
                    width: 8px;
                  }

                  .modal-body::-webkit-scrollbar-track {
                    background: transparent;
                  }

                  .modal-body::-webkit-scrollbar-thumb {
                    background-color: #b08e54;
                    border-radius: 10px;
                  }

                  /* Scrollbar for Firefox */
                  .modal-body {
                    scrollbar-width: thin;
                    scrollbar-color: #b08e54 transparent;
                  }
                `}
                </style>
                <div className="container">
                  <div className="text-white">
                    {/* Full Refund Eligibility */}
                    <section className="">
                      <h2 className="fs-5 fw-bold">Full Refund Eligibility:</h2>
                      <ul className="fs-7">
                        <li>
                          Cancellations made at least one week before the
                          check-in date qualify for a full refund.
                        </li>
                      </ul>
                    </section>

                    {/* Late Cancellation Fees */}
                    <section className="">
                      <h2 className="fs-5 fw-bold">Late Cancellation Fees:</h2>
                      <ul className="fs-7">
                        <li>
                          <strong>
                            Cancellations between 1 week to 48 hours before
                            check-in (12:00 p.m.):
                          </strong>{" "}
                          10% deduction from the total booking amount.
                        </li>
                        <li>
                          <strong>
                            Cancellations between 48 hours to 24 hours before
                            check-in (12:00 p.m.):
                          </strong>{" "}
                          30% deduction from the total booking amount.
                        </li>
                        <li>
                          <strong>
                            Cancellations between 24 hours to 12 hours before
                            check-in (12:00 p.m.):
                          </strong>{" "}
                          50% deduction from the total booking amount.
                        </li>
                        <li>
                          <strong>
                            Cancellations within 12 hours before check-in
                            (12:00 p.m.):
                          </strong>{" "}
                          No refund.
                        </li>
                        <li>
                          <strong>Promotional and Discounted Booking:</strong>{" "}
                          No refund.
                        </li>
                        <li>
                        The management does not initiate partial refunds.
                        </li>
                        <li>
                        Convenience fees will not be refunded.
                        </li>
                      </ul>
                    </section>

                    {/* No-Show Policy */}
                    <section className="">
                      <h2 className="fs-5 fw-bold">No-Show Policy:</h2>
                      <ul className="fs-7">
                        <li>
                          If you do not show up for your reservation, the entire
                          booking amount will be forfeited with no option to
                          reschedule.
                        </li>
                      </ul>
                    </section>

                    {/* Cancellation Procedure */}
                    <section className="mb-4">
                      <h2 className="fs-5 fw-bold">Cancellation Procedure:</h2>
                      <p className="fs-7">
                        To cancel your reservation, please email
                        reservations@hatimiretreats.com from your registered ITS
                        email, including a copy of the invoice and a cancelled
                        cheque. Refunds may take 7-10 working days to process.
                      </p>
                    </section>

                    {/* Management-Initiated Cancellations */}
                    <section className="mb-4">
                      <h2 className="fs-5 fw-bold">
                        Management-Initiated Cancellations:
                      </h2>
                      <p className="fs-7">
                        The management reserves the right to cancel the guest
                        reservations.
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CancelPolicy;
