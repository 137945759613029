
import React from 'react';
import { Link } from 'react-router-dom';

const HomeBlog = (props) => {
  return (
    <>
    <Link to={`/blogs/blogdetail/${props.blogId}`}>
    <img
        src={props.imageUrl}
        className={`blog-card-img-top1 rounded h-100 w-100`}
        alt={`Card ${props.id}`}
      />
            </Link>
      
         <div className="card-body py-3 px-1 ">
        <h2 className="Blog-title text-gold text-start pe-2">{props.title}</h2>
        <p className="blog-description mt-3 fs-7 text-start">{props.description}</p>
        <div className=' d-flex justify-content-center mt-4 '>
          {props.showButton && (
            <Link to={`/blogs/blogdetail/${props.blogId}`} className="btn btn-searchbar">
              {props.button}
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default HomeBlog;

