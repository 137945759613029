import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FaRegUserCircle } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import Cookies from 'js-cookie';
import { useSelector } from "react-redux";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { customerDetails, customerStatus, customerError } = useSelector((state) => state.auth);
  const firstNameInitial = customerDetails?.fullName?.split(' ')[0]?.[0] || '';
  const lastNameInitial = customerDetails?.fullName?.split(' ')[1]?.[0] || '';



  
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: To enable smooth scrolling
    });
    setIsNavOpen(false); // Close the navbar when a NavLink is clicked
  };

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 10;
      if (!isTop) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const navClass = `navbar navbar-expand-lg${
    isScrolled ? " bg-blur" : " bg-transparent"
  }  fixed-top`;


  const handleLogout = () => {
    Cookies.remove('userId');
    Cookies.remove('token');
    Cookies.remove('searchData');
    navigate('/');
  };

  return (
    <nav className={navClass}>
      <div className={`container navbarsize ${isNavOpen ? "navbar-collapse" : ""}`}>
        {/* Logo */}
        <div className="navbar-brand">
          <a href="/home">
            <img
              src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/hatimigold+(2).svg"
              className="img-fluid navbarlogo"
              alt="Hatimi Gold Logo"
            />
          </a>
        </div>
        {/* Navbar Toggler */}
        <button
          className={`navbar-toggler${isNavOpen ? " open" : ""}`}
          type="button"
          onClick={toggleNav}
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        {/* Navbar Links */}
        <div
          className={`collapse navbar-collapse justify-content-end ${
            isNavOpen ? " show" : ""
          }`}
        >
          <ul className="navbar-nav gap-3 text-uppercase ">
            <li className="nav-item">
              <NavLink
                className={`nav-link list ${isActive("/home") ? "active-link gold-text" : ""}`}
                exact
                to="/home"
                onClick={scrollToTop}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link list ${isActive("/destinations") ? "active-link gold-text" : ""}`}
                to="/destinations"
                onClick={scrollToTop}
              >
                Destinations
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link list ${isActive("/events") ? "active-link gold-text" : ""}`}
                to="/events"
                onClick={scrollToTop}
              >
                Events
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link list ${isActive("/daypass") ? "active-link gold-text" : ""}`}
                to="/daypass"
                onClick={scrollToTop}
              >
                Daypass
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link list ${isActive("/blogs") ? "active-link gold-text" : ""}`}
                to="/blogs"
                onClick={scrollToTop}
              >
                Blogs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link list ${isActive("/contact") ? "active-link gold-text" : ""}`}
                to="/contact"
                onClick={scrollToTop}
              >
                Contact Us
              </NavLink>
            </li>

            <Dropdown className="text-center border-0">
              <Dropdown.Toggle
                className="btn btn-secondary border-0 bg-transparent"
                variant="secondary"
                id="userDropdown"
              >
                <div className='profile-image mb-3'>
                            {/* Displaying initials instead of an image */}
                            <div className='navbar-circle rounded-circle'>
                                {firstNameInitial}{lastNameInitial}
                            </div>
                        </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="darkgreen dropdown_border text-white">
                <Dropdown.Item className="bg-transparent text-capitalize">
                  <NavLink
                    className="dropdown-item drop-down-li text-white bg-transparent"
                    to="/mybookings"
                  >
                    My Bookings
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item className="bg-transparent text-capitalize">
                  <NavLink
                    className="dropdown-item drop-down-li text-white bg-transparent"
                    to="/myprofile"
                  >
                    My Profile
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item className="bg-transparent text-capitalize">
                  <NavLink
                    className="dropdown-item drop-down-li text-white bg-transparent"
                    to="/"
                    onClick={handleLogout}
                  >
                    Logout
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
