// src/pages/Blogs.js
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogs } from '../../redux/Blogs/blogSlice';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReactLoading from 'react-loading';
import HomeBlog from '../BolgContent/HomeBlog';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Blogs = () => {
  const dispatch = useDispatch();
  const { blogs, loading, error } = useSelector((state) => state.blogs);
  const carouselRef = useRef(null);

  useEffect(() => {
    // Fetch blogs on component mount
    dispatch(fetchBlogs());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="loading-spinner d-flex justify-content-center align-items-center">
        <ReactLoading type={"balls"} color={"#B08E54"} height={50} width={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-danger">
        <p>Error fetching blogs: {error}</p>
      </div>
    );
  }

  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 3 },
  };

  const slidePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const slideNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const handleImageError = (event) => {
    event.target.src = "https://hatimi.s3.amazonaws.com/frontendpropertyImages/Untitled-1+1.jpg";
  };

  return (
    <div className="container my-5">
      <div className="blogs text-white">
        <span className="mx-5 px-5 mobileresponsive">BLOGS</span>
        <div className='d-flex justify-content-start align-items-start '>
          <img src="https://hatimi.s3.amazonaws.com/frontendpropertyImages/line.svg" className="linehome mx-3 mt-4 pt-3" alt="line" />
          <h1 className='mobileresponsive'>Our Travel Stories</h1>
        </div>
        <div className="mx-5 px-5 mobileresponsive d-flex align-items-center other-room-carousel-wrapper">
          <AliceCarousel
            responsive={responsive}
            mouseTracking
            buttonsDisabled
            autoPlay
            autoPlayInterval={3000}
            ref={carouselRef}
            disableButtonsControls
          >
            {blogs.map((blog) => (
              <div key={blog._id} className="text-white home-blog-background rounded px-0">
                <HomeBlog
                  imageUrl={blog.imageUrl}
                  onError={handleImageError}
                  title={blog.title}
                  description={blog.description}
                  showButton={false}
                  imageHeight={'h-75 w-100'}
                  blogId={blog._id}
                />
              </div>
            ))}
          </AliceCarousel>
          <div className="d-flex justify-content-between align-items-center">
            <button onClick={slidePrev} className="arrow-btn prev m-0">
              <FiChevronLeft className="fs-1" />
            </button>
            <button onClick={slideNext} className="arrow-btn next m-0">
              <FiChevronRight className="fs-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
